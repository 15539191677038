import { GetterTree } from 'vuex'
import { IDashboardState } from './types'
import { RootState } from '@/store'

export default {
  isCustomerJobsLoaded: (state: IDashboardState) => state.customerActiveJobs.isLoaded &&
    state.customerActiveGigs.isLoaded &&
    state.customerActiveVacancies.isLoaded,
  isCustomerJobsLoading: (state: IDashboardState) => state.customerActiveJobs.isLoading ||
    state.customerActiveGigs.isLoading ||
    state.customerActiveVacancies.isLoading,
  isFreelancerJobsLoaded: (state: IDashboardState) => state.freelancerActiveJobs.isLoaded &&
    state.freelancerActiveVacancies.isLoaded &&
    state.freelancerActiveGigs.isLoaded,
  isFreelancerJobsLoading: (state: IDashboardState) => state.freelancerActiveJobs.isLoading ||
    state.freelancerActiveVacancies.isLoading ||
    state.freelancerActiveGigs.isLoading,
} as GetterTree<IDashboardState, RootState>
