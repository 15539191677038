import { Commit, Module } from 'vuex'
import VacancyRoleCategory from '@/models-ts/vacancies/VacancyRoleCategory'
import { getCategoryRoles } from '@/api/vacancies'
import { IVacancyRolesState, RoleOption } from './types'
import LoadableModel from '@/models-ts/LoadableModel'
import { RootState } from '@/store'

const getInitialState = (): IVacancyRolesState => ({
  roles: new LoadableModel({ value: [] }),
})

export default (): Module<IVacancyRolesState, RootState> => ({
  namespaced: true,
  state: getInitialState(),
  mutations: {
    beforeReady (state) {
      state.roles = new LoadableModel(state.roles)
    },
    setRolesLoading: (state) => {
      state.roles.loading()
    },
    setRolesLoaded: (state, roles) => {
      state.roles.loaded(roles)
    },
  },
  getters: {
    roleOptions: state => (state.roles.value || []).reduce((roles: Array<RoleOption>, category: VacancyRoleCategory) => {
      roles.push({ id: category.id, name: category.name, isCategory: true, readOnly: true })
      roles.push(...category.primaryRoles.map(role => ({ id: role.id, name: role.name })))
      return roles
    }, []),
    getRoleByURL: state => (url: string) => {
      for (const cat of state.roles.value as VacancyRoleCategory[]) {
        const role = cat.primaryRoles.find(role => role.url === url)
        if (role) {
          return role
        }
      }
    },
  },
  actions: {
    async getRoles ({ commit, state }: { commit: Commit, state: IVacancyRolesState }) {
      if (state.roles.isLoaded) {
        return state.roles.value
      }
      if (state.roles.isLoading && state.roles.promise) {
        return state.roles.promise.promise
      }
      commit('setRolesLoading')
      const data = await getCategoryRoles()
      const roles = data.map(VacancyRoleCategory.fromServer)
      commit('setRolesLoaded', roles)
      return roles
    },
  }
})
