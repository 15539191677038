import { render, staticRenderFns } from "./MenuNew.htm?vue&type=template&id=382aedea&scoped=true&"
import script from "./MenuNew.js?vue&type=script&lang=js&"
export * from "./MenuNew.js?vue&type=script&lang=js&"
import style0 from "./MenuNew.scss?vue&type=style&index=0&id=382aedea&lang=scss&scoped=true&"
import style1 from "./MenuNewPopover.scss?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "382aedea",
  null
  
)

export default component.exports