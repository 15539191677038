import { render, staticRenderFns } from "./SomeError.htm?vue&type=template&id=0f93ffab&scoped=true&"
import script from "./SomeError.js?vue&type=script&lang=js&"
export * from "./SomeError.js?vue&type=script&lang=js&"
import style0 from "./SomeError.scss?vue&type=style&index=0&id=0f93ffab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f93ffab",
  null
  
)

export default component.exports