import cloneDeep from 'lodash/cloneDeep'
import unescape from 'lodash/unescape'
import get from 'lodash/get'
import Skill, { SkillFromServer } from '@/models-ts/Skill'
import { Avatar } from '@/models/user'
import { WorkExperience } from '@/constants/vacancies/experiences'
import { PositionType } from '@/constants/vacancies/positionTypes'
import { VacancyRecruiterFromServer } from './VacancyRecruiter'
import { parseSlug } from '@/utils-ts/parser'
import { stripDescriptionTags } from '@/utils-ts/strings'
import VacancyRole from './VacancyRole'
import VacancyLocation, { VacancyLocationFromServer } from './VacancyLocation'
import { CustomerTypes } from '@/constants/user/roles'

export default class VacancyListItem {
  id: string | number
  user?: VacancyUserItem | null
  bookmarkId?: number
  slug: string
  name: string
  created_at: string
  updated_at: string
  published_at?: string
  description: string
  locations: VacancyLocation[]
  payments_is_crypto: number
  position_office: boolean
  position_remote: boolean
  position_type: PositionType
  primaryRoleName: VacancyRole['name']
  primaryRole: VacancyRole
  salary_type: number
  salary_from: number
  salary_to: number
  work_experience: WorkExperience
  skills: Array<Skill>
  is_featured: boolean

  constructor (data: Partial<VacancyListItem>) {
    Object.assign(this, cloneDeep({
      id: data.id,
      user: data.user,
      bookmarkId: data.bookmarkId,
      slug: data.slug,
      name: unescape(data.name),
      description: data.description,
      created_at: data.created_at,
      updated_at: data.updated_at,
      payments_is_crypto: data.payments_is_crypto,
      published_at: data.published_at,
      position_office: data.position_office,
      position_remote: data.position_remote,
      position_type: data.position_type,
      primaryRoleName: data.primaryRoleName,
      primaryRole: data.primaryRole,
      is_featured: data.is_featured,
      salary_type: data.salary_type,
      salary_to: data.salary_to,
      salary_from: data.salary_from,
      locations: data.locations,
      skills: data.skills,
      work_experience: data.work_experience,
    }))
  }

  static fromServer (data: VacancyListItemFromServer) {
    const primaryRole = get(data, 'primaryRoles[0]', { id: null, name: null })
    return new VacancyListItem({
      ...data,
      user: {
        id: data?.user?.id,
        type: data?.user?.type,
        name: data?.user?.name,
        avatar: Avatar.fromServer(data?.user?.avatar || {}),
        is_customer_verified: Number(data?.user?.is_customer_verified) === 1,
        profile: {
          type: data?.user?.profile?.type,
        }
      },
      is_featured: Number(data.is_featured) === 1,
      position_office: Boolean(Number(data.position_office)),
      position_remote: Boolean(Number(data.position_remote)),
      published_at: data.first_published_at,
      bookmarkId: get(data.bookmarks, '[0].id'),
      slug: parseSlug(data.slug),
      description: stripDescriptionTags(data.description, { stripLinks: true }),
      primaryRoleName: primaryRole.name,
      primaryRole: VacancyRole.fromServer(primaryRole),
      salary_type: Number(data.salary_type),
      salary_from: Number(data.salary_from || 0),
      salary_to: Number(data.salary_to || 0),
      locations: (data.locations || []).map(VacancyLocation.fromServer),
      skills: (data.skills || []).map(Skill.fromServer),
    })
  }

  get hasCompensation () {
    return this.salary_from > 0 || this.salary_to > 0
  }

  get remoteInfo () {
    if (this.position_remote) {
      return this.position_office ? 'Hybrid' : 'Remote'
    }
    return 'Office'
  }
}

export type VacancyListItemFromServer = {
  id: string | number
  user?: VacancyUserItem | null
  bookmarks: Array<{id: number}> | null
  slug: string
  name: string
  created_at: string
  updated_at: string
  first_published_at: string
  description: string
  locations: VacancyLocationFromServer[]
  payments_is_crypto: number
  position_office: '0' | '1'
  position_remote: '0' | '1'
  is_featured: 0 | 1 | '0' | '1'
  position_type: PositionType
  salary_type: number | string
  salary_from: string
  salary_to: string
  work_experience: WorkExperience
  primaryRoles: Array<Required<VacancyRole>>
  skills: Array<SkillFromServer>
  relations: {
    Customer: VacancyRecruiterFromServer
  }
}

type VacancyUserItem = {
  id?: string | number
  type?: string | number
  name?: string
  avatar?: {}
  is_customer_verified?: 0 | 1 | '0' | '1' | null | boolean
  profile?: {
    type?: CustomerTypes
  }
}
