export default class VacancyRole {
  id: number
  name: string
  url: string

  constructor ({ id, name, url }: Partial<VacancyRole>) {
    Object.assign(this, { id, name, url })
  }

  static fromServer (props: Required<VacancyRole>) {
    return new VacancyRole({
      ...props,
    })
  }
}
