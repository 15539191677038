export enum FileCategories {
  CAT_IMG_ORIGINAL = 1,
  CAT_IMG_100 = 2,
  CAT_IMG_180 = 3,
  CAT_IMG_200 = 4,
  CAT_IMG_360 = 5,
  CAT_CONTRACT = 6,
  CAT_INVOICE = 7,
  CAT_GENERATED_PDF = 9,
  CAT_IMG_CROPPED = 10,
  CAT_IMG_RESIZED = 11,
}
