import { render, staticRenderFns } from "./InfoMessage.htm?vue&type=template&id=5847eae6&scoped=true&"
import script from "./InfoMessage.ts?vue&type=script&lang=js&"
export * from "./InfoMessage.ts?vue&type=script&lang=js&"
import style0 from "./InfoMessage.scss?vue&type=style&index=0&id=5847eae6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5847eae6",
  null
  
)

export default component.exports