// @ts-ignore
import NoSsr from 'vue-no-ssr'
import Vue from 'vue'
import LxHeader from '@/partials/LxHeader/LxHeader.vue'
import PublicFooter from '@/partials/PublicFooter/PublicFooter.vue'
import SidePanel from '@/partials/SidePanel/SidePanel.vue'
import { mapActions, mapState } from 'vuex'
import { RootState } from '@/store'
import { RouteRecord } from 'vue-router'
import responseMixin from '@/mixins/responseMixin'
import {
  BROWSE_JOBS,
  BROWSE_JOBS_BY_SKILL,
  BROWSE_VACANCIES_BY_SKILL,
  DASHBOARD,
  FREELANCERS_LIST,
  FREELANCERS_LIST_BY_SKILL,
  JOB_ADD,
  JOB_EDIT,
  LEAVE,
  NOT_FOUND,
  POST_A_JOB,
  SERVICES, SERVICE_ADD, SERVICE_BY_SKILL,
  SERVICE_EDIT,
  VACANCIES,
  VACANCY_ADD,
  VACANCY_EDIT,
} from '@/constants/routes'
import SkillsMenu from '@/partials/SkillsMenu/SkillsMenu.vue'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import rolebleMixin from '@/mixins/rolebleMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, rolebleMixin],
  components: {
    PublicFooter,
    LxHeader,
    NoSsr,
    SidePanel,
    SkillsMenu,
  },
  props: {
    classes: String,
    publicOnly: Boolean,
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      isLoaded: (state: RootState) => state.user?.profile?.isLoaded,
      isProfileLoading: (state: RootState) => state.user?.profile.isLoading,
      customerIsNewbie: (state: RootState) => state.user?.customerIsNewbie,
      roleChanging: (state: RootState) => state.user?.roleChanging,
    }),
    hasSkillsMenu () {
      return [
        BROWSE_JOBS,
        BROWSE_JOBS_BY_SKILL,
        SERVICES,
        SERVICE_BY_SKILL,
        FREELANCERS_LIST,
        FREELANCERS_LIST_BY_SKILL,
        VACANCIES,
        BROWSE_VACANCIES_BY_SKILL,
      ].includes(this.$route.name)
    },
    isPrivatePage () {
      if (this.publicOnly) return false
      if (this.$route.matched.some((record: RouteRecord) => record.meta.requiresAuth)) return true
      if (this.$route.matched.some((record: RouteRecord) => record.meta.layout === 'public')) return false
      return this.isLoggedIn
    },
    hasPostJobBtn () {
      const job_routes = [JOB_ADD, JOB_EDIT, VACANCY_ADD, VACANCY_EDIT, POST_A_JOB]
      const customerIsNewbie = this.$route.name === DASHBOARD && this.customerIsNewbie && this.isLoggedIn
      return !job_routes.includes(this.$route.name) && !customerIsNewbie
    },
    hasPostGigBtn () {
      const job_routes = [SERVICE_ADD, SERVICE_EDIT]
      return !job_routes.includes(this.$route.name)
    },
    hasPostBtnsSection () {
      return this.isFreelancer ? this.hasPostGigBtn : this.hasPostJobBtn
    },
    hasSidePanel () {
      return this.isPrivatePage && ![POST_A_JOB, JOB_ADD, JOB_EDIT, VACANCY_ADD, VACANCY_EDIT, LEAVE, NOT_FOUND].includes(this.$route.name)
    },
  },
  watch: {
    isPrivatePage: {
      handler () {
        this.loadProfile()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getActiveRole: 'user/getActiveRole',
      getUserInfo: 'user/getUserInfo',
      initialLoad: 'pendingTxs/initialLoad',
    }),
    async loadProfile () {
      try {
        if (process.client && this.isLoggedIn && this.isPrivatePage && !this.isLoaded) {
          await Promise.all([this.getActiveRole(), this.getUserInfo()])
          await this.initialLoad()
        }
      } catch (e) {
        this.parseError(e)
      }
    },
    onCreateGigClick () {
      googleAnalyticsV2.send({
        event: 'click-on-button-post-gig',
      })
      this.$router.push({ name: SERVICE_ADD, query: { createGigFrom: 'profile' } }).catch(() => {})
    },
    onPostJobClick () {
      googleAnalyticsV2.send({
        event: 'post-job-button',
      })
      this.$router.push({ name: POST_A_JOB, query: { fromType: 'add-job-from-profile' } }).catch(() => {})
    },
  }
})
