import Vue, { PropType } from 'vue'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { activate } from '@/api/usersAndProfiles/auth'
import { DASHBOARD, LANDING } from '@/constants/routes'
import { Roles } from '@/constants/user/roles'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { RootState } from '@/store'
import errorImage from '!!raw-loader!./error.svg'
import successImage from '!!raw-loader!./success.svg'
import { confirmSubscription } from '@/api/subsciption'

export default Vue.extend<any, any, any, any>({
  props: {
    token: String,
    type: String as PropType<'sign-up' | 'email' | 'social'>
  },
  data () {
    return {
      errorImage,
      successImage,
      processing: true,
      success: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      params: (state: RootState) => state.analytics.params,
      triggerCondition: (state: RootState) => state.analytics.triggerCondition,
    }),
    ...mapGetters({
      tokenInfo: 'user/tokenInfo',
    }),
    textRole () {
      switch (this.tokenInfo.active_profile) {
        case Roles.CUSTOMER: {
          return 'Customer'
        }
        case Roles.JOB_SEEKER: {
          return 'Job seeker'
        }
        case Roles.RECRUITER: {
          return 'Recruiter'
        }
        default: {
          return 'Talent'
        }
      }
    },
  },
  async mounted () {
    try {
      if (!this.token) {
        this.redirect('')
        return
      }
      if (this.type === 'sign-up') {
        const result = await activate(this.token)
        const redirectUrl = result.redirect_url || DASHBOARD
        this.setToken(result.token)
        this.sendRegisterSuccess()
        this.redirect(redirectUrl)
        if (this.tokenInfo.active_profile !== Roles.CUSTOMER) {
          googleAnalyticsV2.send({ event: 'activation-talent' })
          if (redirectUrl === DASHBOARD) {
            this.openModal({
              component: 'lx-lazy-modal',
              props: {
                factory: import(/* webpackChunkName: "welcome-modal" */ '@/modals/WelcomeModal/WelcomeModal.vue'),
                title: 'Welcome to LaborX!',
                props: {
                  activeRole: this.tokenInfo.active_profile,
                }
              }
            })
          }
        } else {
          googleAnalyticsV2.send({ event: 'activation-customer' })
        }
      } else if (this.type === 'email') {
        await this.confirmEmail(this.token)
        this.redirect(DASHBOARD)
      } else if (this.type === 'subscription') {
        await confirmSubscription(this.token)
        this.redirect(LANDING, () => {
          if (!this.isLoggedIn) {
            this.openModal({
              component: 'lx-sign-up-modal',
              props: {
                fromType: 'subscribe-confirmation',
              }
            })
          }
        })
      } else if (this.type === 'social') {
        this.setToken(this.token)
        this.redirect(DASHBOARD)
      }
      this.success = true
    } catch (e) {
      console.error(e)
    } finally {
      this.processing = false
    }
  },
  methods: {
    ...mapActions({
      confirmEmail: 'user/confirmEmail',
      openModal: 'ui/openModal',
      setToken: 'app/setToken',
    }),
    ...mapMutations({
      resetTriggerCondition: 'analytics/resetTriggerCondition',
      resetParams: 'analytics/resetParams',
    }),
    sendRegisterSuccess () {
      const userid = this.tokenInfo?.user_id
      const analyticsParams = {
        event: 'register-success',
        name: '',
        account: 'Y',
        role: this.textRole,
        'hit-role': this.textRole,
        'role-customer': '',
        'event-content': '',
        ...this.params,
        userid,
      }
      googleAnalyticsV2.send(analyticsParams)
      this.resetTriggerCondition()
      this.resetParams()
    },
    redirect (to: string, callback?: () => void) {
      setTimeout(() => {
        if (to === DASHBOARD) {
          this.$router.push({ name: to })
        } else {
          const urlPart = to.split('/').slice(3).join('/')
          this.$router.push(`/${urlPart}`)
        }
        callback?.()
      }, 1000)
    }
  },
  metaInfo: {
    title: 'Confirmation',
    meta: [
      {
        vmid: 'robots',
        name: 'robots',
        content: 'noindex',
      },
    ]
  },
})
