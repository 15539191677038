import { render, staticRenderFns } from "./LxHeader.htm?vue&type=template&id=4ade53ca&scoped=true&"
import script from "./LxHeader.ts?vue&type=script&lang=js&"
export * from "./LxHeader.ts?vue&type=script&lang=js&"
import style0 from "./LxHeader.scss?vue&type=style&index=0&id=4ade53ca&lang=scss&scoped=true&"
import style1 from "./LxHeaderMenu.scss?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ade53ca",
  null
  
)

export default component.exports