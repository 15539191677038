export const LANDING = 'landing'
export const HIRE_LANDING = 'hire-landing'
export const BROWSE_JOBS = 'browse-jobs'
export const BROWSE_JOBS_BY_SKILL = 'browse-jobs-by-skill'
export const CUSTOMER_PROFILE = 'customer-profile'
export const FREELANCER_PROFILE = 'freelancer-profile'
export const FREELANCERS_LIST = 'freelancers-list'
export const FREELANCERS_LIST_BY_SKILL = 'freelancers-list-by-skill'
export const DASHBOARD = 'dashboard'
export const USER_NOTIFICATIONS = 'user-notifications'
export const JOB_ADD = 'job-add'
export const JOB_EDIT = 'job-edit'
export const JOB_DETAILS = '/jobs/{slug}-{id}'
export const JOB_DETAILS_BY_ID = 'job-details-by-id'
export const JOB_DETAILS_ADMIN = 'job-details-admin'
export const JOB_DETAILS_ADMIN_DESCRIPTION = 'job-details-admin-description'
export const JOB_DETAILS_ADMIN_APPLICATIONS = 'job-details-admin-applications'
export const JOB_CHAT = 'job-chat'
export const JOB_CHAT_BY_ID = 'job-chat-by-id'

export const MY_JOBS = 'my-jobs'
export const JOBS_ALL = 'jobs-all'
export const JOBS_OFFERS = 'jobs-offers'
export const JOBS_NEGOTIATIONS = 'jobs-negotiations'
export const JOBS_FINISHED = 'jobs-finished'
export const JOBS_TO_DO = 'jobs-to-do'
export const JOBS_IN_PROGRESS = 'jobs-in-progress'
export const JOBS_COMPLETED = 'jobs-completed'
export const JOBS_POSTED = 'jobs-posted'

export const PREMIUM = 'premium'

export const SETTINGS = 'settings'
export const PROFILE_SETTINGS = 'profile-settings'

export const AUTH_CONFIRM = 'auth-confirm'
export const EMAIL_CONFIRM = 'email-confirm'
export const SOCIAL_CONFIRM = 'social-confirm'
export const SUBSCRIBE_CONFIRMATION = 'subscribe-confirmation'
export const REGISTRATION_COMPLETE = 'registration-complete'

export const MODULE = 'module'
export const PROFILE = 'profile'
export const SECURITY = 'security'
export const WALLETS = 'wallets'
export const NOTIFICATIONS = 'notifications'
export const REFERRALS = 'referrals'
export const NOT_FOUND = 'not-found'

export const BLOG = 'blog'
export const POST = 'post'

export const SKILLS_LIST = 'skills-list'

export const ABOUT_US = 'about-us'

export const JOB_MINING = 'job-mining'

export const SERVICES = 'services'
export const SERVICE_BY_SKILL = 'service-browse-by-skill'
export const SERVICE_ADD = 'service-add'
export const SERVICE_EDIT = 'service-edit'
export const SERVICE_DETAILS = '/gigs/{slug}-{id}'
export const SERVICE_DETAILS_BY_ID = 'service-details-by-id'
export const SERVICE_MY = 'my-service'
export const SERVICE_MY_POSTED = 'my-service-posted'
export const SERVICE_MY_OFFERS = 'my-service-offers'
export const SERVICE_MY_IN_PROGRESS = 'my-service-in-progress'
export const SERVICE_MY_COMPLETED = 'my-service-completed'
export const SERVICE_MY_DRAFTS = 'my-service-drafts'
export const SERVICE_MY_ARCHIVED = 'my-service-archived'
export const SERVICE_CHAT = 'service-chat'
export const SERVICE_CHAT_BY_ID = 'service-chat-by-id'

export const VACANCIES = 'vacancy'
export const BROWSE_VACANCIES_BY_SKILL = 'vacancy-by-skill'
export const BROWSE_VACANCIES_BY_SKILL_COUNTRY = 'vacancy-by-skill-country'
export const BROWSE_VACANCIES_BY_SKILL_COUNTRY_CITY = 'vacancy-by-skill-country-city'
export const BROWSE_VACANCIES_BY_ROLE = 'vacancy-by-role'
export const VACANCY_ADD = 'vacancy-add'
export const VACANCY_EDIT = 'vacancy-edit'
export const VACANCY_DETAILS = '/vacancies/{slug}-{id}'
export const VACANCY_EXTERNAL_APPLY = 'vacancy-external-apply'
export const VACANCY_DETAILS_BY_ID = 'vacancy-details-by-id'
export const VACANCY_DETAILS_ADMIN = 'vacancy-details-admin'
export const VACANCY_DETAILS_ADMIN_DESCRIPTION = 'vacancy-details-admin-description'
export const VACANCY_DETAILS_ADMIN_APPLICATIONS = 'vacancy-details-admin-applications'
export const VACANCY_DETAILS_ADMIN_AI_RECOMMENDED = 'vacancy-details-admin-ai-recommended'
export const VACANCIES_MY = 'my-vacancies'
export const VACANCIES_MY_PUBLISHED = 'my-vacancies-published'
export const VACANCIES_MY_DRAFTS = 'my-vacancies-drafts'
export const VACANCIES_MY_ARCHIVED = 'my-vacancies-archived'
export const VACANCIES_MY_ALL = 'my-vacancies-all'
export const VACANCIES_MY_APPLIED = 'my-vacancies-applied'
export const VACANCIES_MY_IN_PROGRESS = 'my-vacancies-in-progress'
export const VACANCIES_SAVED = 'vacancies-saved'
export const VACANCIES_MY_APPLICATIONS = 'vacancies-my-applications'
export const VACANCIES_CREATE_APPLICATIONS = 'vacancies-create-application'
export const VACANCY_CHAT = 'vacancy-chat'
export const VACANCY_CHAT_BY_ID = 'vacancy-chat-by-id'
export const BOOKMARKS = 'bookmarks'
export const GIG_BOOKMARKS = 'gig-bookmarks'
export const VACANCY_BOOKMARKS = 'vacancy-bookmarks'
export const JOB_BOOKMARKS = 'job-bookmarks'

export const TRANSACTIONS = 'transactions'
export const POST_A_JOB = 'post-a-job'

export const CHAT = 'chat'
export const CHAT_FREELANCE = 'chat-freelance'
export const CHAT_FULL_TIME = 'chat-full-time'
export const CHAT_ARCHIVED = 'chat-archive'

export const LEAVE = 'leaving'
