export default class Rate {
  currency: string | 'BTC' | 'ETH' | 'USDT' | 'WBTC' | 'TIME' | 'USDC' | 'DAI' | 'BNB' | 'BUSD' | 'AUDT'
  id: number
  market: number
  pair: 'USD'
  price: string
  constructor (props: Partial<Rate>) {
    Object.assign(this, props)
  }
}
