import Vue from 'vue'

export default Vue.extend({
  name: 'lx-description-quality',
  props: {
    length: Number,
  },
  computed: {
    level () {
      if (this.length < 50) {
        return 'Poor'
      }
      if (this.length < 100) {
        return 'Good'
      }
      return 'Excellent'
    },
    levelHint (): string | undefined {
      switch (this.level) {
        case 'Poor': return 'Your description is too short, please write at least 50 characters.'
        case 'Good': return 'To improve your description, try writing up to 100 characters.'
        case 'Excellent': return 'Your description is excellent. Nice work!'
      }
    },
  },
})
