import { GigStatuses } from '@/constants/gig/gigStatuses'
import { BACKEND_PRIVATE, BACKEND_PUBLIC, BACKEND_LOYAL } from './base'
import { ModerationStages } from '@/constants/backend/ModerationStages'

export interface DisputeData {
  comment: string,
  files?: Array<Partial<UploadedFile>>,
}

export async function getBase64Image (url: string) {
  const data = await BACKEND_PUBLIC.get('/images/to-base64', { params: { url } })
  return data
}

export async function createService (payload: any) {
  const data = await BACKEND_PRIVATE.post('/me/gig/create', { payload })
  return data
}

export async function editService (id: number, payload: any) {
  const data = await BACKEND_PRIVATE.put('/me/gig/edit', { id, payload })
  return data
}

export async function getService (id: number | string) {
  return BACKEND_LOYAL.get('/gig/get', { params: { id } })
}

export async function publishService (id: number) {
  return BACKEND_PRIVATE.post('/me/gig/publish', { id })
}

export async function unpublishService (id: number) {
  return BACKEND_PRIVATE.post('/me/gig/unpublish', { id })
}

export async function getMyServices (
  { limit, offset, status, moderationStages }:
  { limit: number, offset: number, status?: GigStatuses, moderationStages?: ModerationStages[] }
) {
  return BACKEND_PRIVATE.get('/me/gig/list', {
    params: {
      limit,
      offset,
      status,
      moderationStages,
    }
  })
}

export async function getFreelancerOffers (limit: number, offset: number) {
  return BACKEND_PRIVATE.get('/me/gigs/freelancer/offers', {
    params: {
      limit: limit,
      offset
    }
  })
}

export async function getFreelancerNegotiationsOffers (limit: number, offset: number) {
  return BACKEND_PRIVATE.get('/me/gigs/applications/negotiations-as-freelancer', {
    params: {
      limit: limit,
      offset
    }
  })
}

export async function getFreelancerInProgress (limit: number, offset: number) {
  return BACKEND_PRIVATE.get('/me/gigs/freelancer/in-progress', {
    params: {
      limit: limit,
      offset
    }
  })
}

export async function getFreelancerCompleted (limit: number, offset: number) {
  return BACKEND_PRIVATE.get('/me/gigs/freelancer/completed', {
    params: {
      limit: limit,
      offset
    }
  })
}

export async function getCustomerNegotiationsOffers (limit: number, offset: number) {
  return BACKEND_PRIVATE.get('/me/gigs/applications/negotiations-as-customer', {
    params: {
      limit: limit,
      offset
    }
  })
}

export async function getCustomerInProgressOffers (limit: number, offset: number) {
  return BACKEND_PRIVATE.get('/me/gigs/customer/in-progress', {
    params: {
      limit: limit,
      offset
    }
  })
}

export async function getCustomerOffers (limit: number, offset: number) {
  return BACKEND_PRIVATE.get('/me/gigs/customer/offers', {
    params: {
      limit: limit,
      offset
    }
  })
}

export async function getCustomerInProgress (limit: number, offset: number) {
  return BACKEND_PRIVATE.get('/me/gigs/customer/in-progress', {
    params: {
      limit: limit,
      offset
    }
  })
}

export async function getCustomerCompleted (limit: number, offset: number) {
  return BACKEND_PRIVATE.get('/me/gigs/customer/completed', {
    params: {
      limit: limit,
      offset
    }
  })
}

export async function getGigOffer (id: number) {
  return BACKEND_PRIVATE.get('/me/gigs/offers/get', { params: { id } })
}

export async function deleteService (id: number) {
  return BACKEND_PRIVATE.delete('/me/gig/remove', { data: { id } })
}

export async function getGigs (params: any) {
  return BACKEND_PUBLIC.get('gig/list-by-rating', { params })
}

export async function getServices (params: any) {
  return BACKEND_PUBLIC.get('gig/list-by-skill', { params })
}

export async function getGigsList (params: any) {
  return BACKEND_PUBLIC.get('gig/list', { params })
}

export async function sendOffer (payload: any) {
  return BACKEND_PRIVATE.post('/me/gigs/offers/send', { payload })
}

export async function applyOffer (id: number) {
  return BACKEND_PRIVATE.post(`/me/gigs/offers/apply?id=${id}`)
}

export async function declineOffer ({ id, comment }: { id: number, comment: string }) {
  return BACKEND_PRIVATE.post(`/me/gigs/offers/decline?id=${id}`, { comment })
}

export async function startDispute ({ id, params }: {id: number, params: DisputeData }) {
  return BACKEND_PRIVATE.post(`/me/gigs/jobs/block?id=${id}`, params)
}

export async function getGigJob (id: number) {
  return BACKEND_PRIVATE.get('me/gigs/jobs/get', { params: { id } })
}

export async function getApplication (id: number) {
  return BACKEND_PRIVATE.get('/me/gigs/applications/get', { params: { id } })
}

export async function sendApplication (payload: any) {
  return BACKEND_PRIVATE.post('/me/gigs/applications/create-as-customer', { payload })
}

export async function declineApplicationAsCustomer ({ id, reason }: { id: number, reason: string }) {
  return BACKEND_PRIVATE.post(`/me/gigs/applications/decline-as-customer?id=${id}`, { reason })
}

export async function declineApplicationAsFreelancer ({ id, reason }: { id: number, reason: string }) {
  return BACKEND_PRIVATE.post(`/me/gigs/applications/decline-as-freelancer?id=${id}`, { reason })
}

export async function doneGig (id: number) {
  return BACKEND_PRIVATE.post(`/me/gigs/jobs/mark-done?id=${id}`)
}

export async function cancelDoneGig (id: number) {
  return BACKEND_PRIVATE.post(`/me/gigs/jobs/mark-not-done?id=${id}`)
}

export async function getOffersAsFreelancer (params: string) {
  return BACKEND_PRIVATE.get(`/me/gigs/offers/list-as-freelancer`, { params })
}

export async function getOffersAsCustomer (params: string) {
  return BACKEND_PRIVATE.get(`/me/gigs/offers/list-as-customer`, { params })
}

export async function getRecommendedGigs ({ limit, offset }: { limit: number, offset: number }) {
  return BACKEND_PRIVATE.get('/me/gig/list-of-recommended', {
    params: {
      limit: limit,
      offset: offset
    }
  })
}

export async function readApplications (ids: number[]) {
  return BACKEND_PRIVATE.post(`/me/gigs/applications/mark-read`, null, { params: { ids } })
}
