import { BACKEND_PRIVATE, BACKEND_PUBLIC } from './base'

export enum SubscriptionType {
  FREELANCER_SUBSCRIBE_REQUEST = 1,
  CUSTOMER_SUBSCRIBE_REQUEST = 2,
  NEW_FULL_TIME_JOBS_REQUEST = 3,
}

interface SubscribeRequest {
  type: SubscriptionType
  email: string
  phone?: string
  name?: string
  reCaptcha: string
}

export function createSubscription (payload: SubscribeRequest) {
  return BACKEND_PUBLIC.post('/subscription-requests/create', { payload })
}

export function confirmSubscription (token: string) {
  return BACKEND_PUBLIC.get('/subscription-requests/check-token', { params: { token } })
}
