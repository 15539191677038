import DashboardProfileInfo from '@/models-ts/dashboard/DashboardProfileInfo'
import { IDashboardState } from './types'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import { getInitialState } from './helper'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import MyGigApplication from '@/models-ts/gigs/MyGigApplication'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import JobApplication from '@/models-ts/job/JobApplication'
import MyGigListItem from '@/models-ts/gigs/MyGigListItem'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import { GigStatuses } from '@/constants/gig/gigStatuses'
import { MutationTree } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { Statuses } from '@/constants/vacancies/statuses'

export default {
  resetState (state) {
    Object.assign(state, getInitialState())
  },
  setProfileInfoLoading (state) {
    state.profileInfo.loading()
  },
  rejectProfileInfoLoading (state) {
    state.profileInfo.reject()
  },
  setProfileInfoLoaded (state, data: DashboardProfileInfo) {
    state.profileInfo.loaded(data)
  },
  setCustomerJobsLoading (state) {
    state.customerActiveJobs.loading()
    state.customerArchiveJobs.loading()
    state.customerDraftJobs.loading()
  },
  setCustomerJobsLoaded (state, {
    draft,
    active,
    archive
  }: {
    draft: Array<MyCustomerJobListItem>
    active: Array<MyCustomerJobListItem>
    archive: Array<MyCustomerJobListItem>
  }) {
    state.customerActiveJobs.loaded(active)
    state.customerArchiveJobs.loaded(archive)
    state.customerDraftJobs.loaded(draft)
  },
  setCustomerGigsLoading (state) {
    state.customerActiveGigs.loading()
    state.customerArchiveGigs.loading()
  },
  setCustomerGigsLoaded (state, {
    active,
    archive
  }: {
    active: Array<MyGigApplication | GigOfferListItem>
    archive: Array<GigOfferListItem>
  }) {
    state.customerActiveGigs.loaded(active)
    state.customerArchiveGigs.loaded(archive)
  },
  setCustomerVacanciesLoading (state) {
    state.customerActiveVacancies.loading()
    state.customerArchiveVacancies.loading()
    state.customerDraftVacancies.loading()
  },
  setCustomerVacanciesLoaded (state, {
    draft,
    active,
    archive
  }: {
    draft: Array<MyVacancyListItem>
    active: Array<MyVacancyListItem>
    archive: Array<MyVacancyListItem>
  }) {
    state.customerActiveVacancies.loaded(active)
    state.customerArchiveVacancies.loaded(archive)
    state.customerDraftVacancies.loaded(draft)
  },
  setFreelancerJobsLoading (state) {
    state.freelancerActiveJobs.loading()
    state.freelancerArchiveJobs.loading()
  },
  setFreelancerJobsLoaded (state, {
    active,
    archive
  }: {
    active: Array<JobApplication>
    archive: Array<JobApplication>
  }) {
    state.freelancerActiveJobs.loaded(active)
    state.freelancerArchiveJobs.loaded(archive)
  },
  setFreelancerGigsLoading (state) {
    state.freelancerActiveGigs.loading()
    state.freelancerDraftGigs.loading()
    state.freelancerArchiveGigs.loading()
  },
  setFreelancerGigsLoaded (state, {
    active,
    draft,
    archive
  }: {
    active: Array<MyGigListItem | MyGigApplication | GigOfferListItem>
    draft: Array<MyGigListItem>
    archive: Array<GigOfferListItem>
  }) {
    state.freelancerActiveGigs.loaded(active)
    state.freelancerDraftGigs.loaded(draft)
    state.freelancerArchiveGigs.loaded(archive)
  },
  setFreelancerVacanciesLoading (state) {
    state.freelancerActiveVacancies.loading()
    state.freelancerArchiveVacancies.loading()
  },
  setFreelancerVacanciesLoaded (state, {
    active,
    archive
  }: {
    active: Array<VacancyApplication>
    archive: Array<VacancyApplication>
  }) {
    state.freelancerActiveVacancies.loaded(active)
    state.freelancerArchiveVacancies.loaded(archive)
  },
  setCustomerJobPublish (state, job: MyCustomerJobListItem) {
    const draftJobs = state.customerDraftJobs.value.slice(0)
    const jobIndex = draftJobs.findIndex((j: MyCustomerJobListItem) => j.id === job.id)
    draftJobs.splice(jobIndex, 1)
    state.customerDraftJobs.loaded(draftJobs)
    state.customerActiveJobs.value.push(job)
  },
  setCustomerJobUnpublish (state, job: MyCustomerJobListItem) {
    const activeJobs = state.customerActiveJobs.value.slice(0)
    const jobIndex = activeJobs.findIndex((j: MyCustomerJobListItem) => j.id === job.id)
    activeJobs.splice(jobIndex, 1)
    state.customerActiveJobs.loaded(activeJobs)
    state.customerDraftJobs.value.push(job)
  },
  setCustomerJobDelete (state, { job, isActive }: { job: MyCustomerJobListItem, isActive: Boolean }) {
    const jobs = (isActive ? state.customerActiveJobs : state.customerDraftJobs).value.slice(0)
    const jobIndex = jobs.findIndex((j: MyCustomerJobListItem) => j.id === job.id)
    jobs.splice(jobIndex, 1)
    if (isActive) {
      state.customerActiveJobs.loaded(jobs)
    } else {
      state.customerDraftJobs.loaded(jobs)
    }
  },
  setCustomerVacancyDelete (state, { job, type }: { job: MyVacancyListItem, type: 'active' | 'draft' | 'archive' }) {
    const mapper = new Map([
      ['active', state.customerActiveVacancies],
      ['draft', state.customerDraftVacancies],
      ['archive', state.customerArchiveVacancies],
    ])
    const vacancies = mapper.get(type)?.value.slice(0)
    if (vacancies) {
      const vacancyIndex = vacancies.findIndex((v: MyVacancyListItem) => v.id === job.id)
      vacancies.splice(vacancyIndex, 1)
      mapper.get(type)?.loaded(vacancies)
    }
  },
  setCustomerVacancyArchive (state, { job, type }: { job: MyVacancyListItem, type: 'active' | 'draft' }) {
    const mapper = new Map([
      ['active', state.customerActiveVacancies],
      ['draft', state.customerDraftVacancies],
    ])
    const vacancies = mapper.get(type)?.value
    if (vacancies) {
      const vacancyIndex = vacancies.findIndex((v: MyVacancyListItem) => v.id === job.id)
      vacancies.splice(vacancyIndex, 1)
      mapper.get(type)?.loaded(vacancies)
      state.customerArchiveVacancies.loaded([...state.customerArchiveVacancies.value, job])
    }
  },
  setCustomerVacancyPublish (state, vacancy: MyVacancyListItem) {
    const draftVacancies = state.customerDraftVacancies.value.slice(0)
    const vacancyIndex = draftVacancies.findIndex((v: MyVacancyListItem) => v.id === vacancy.id)
    draftVacancies.splice(vacancyIndex, 1)
    state.customerDraftVacancies.loaded(draftVacancies)
    state.customerActiveVacancies.value.push(vacancy)
  },
  setCustomerVacancyUnArchive (state, vacancy: MyVacancyListItem) {
    const archivedVacancies = cloneDeep(state.customerArchiveVacancies.value)
    const vacancyIndex = archivedVacancies.findIndex((v: MyVacancyListItem) => v.id === vacancy.id)
    archivedVacancies.splice(vacancyIndex, 1)
    state.customerArchiveVacancies.loaded(archivedVacancies)
    if (vacancy.status === Statuses.DRAFT) {
      state.customerDraftVacancies.loaded([...state.customerDraftVacancies.value, vacancy])
    }
    if (vacancy.status === Statuses.PUBLISHED) {
      state.customerActiveVacancies.loaded([...state.customerActiveVacancies.value, vacancy])
    }
  },
  setCustomerVacancyUnpublish (state, vacancy: MyVacancyListItem) {
    const activeVacancies = state.customerActiveVacancies.value.slice(0)
    const vacancyIndex = activeVacancies.findIndex((v: MyVacancyListItem) => v.id === vacancy.id)
    activeVacancies.splice(vacancyIndex, 1)
    state.customerActiveVacancies.loaded(activeVacancies)
    state.customerDraftVacancies.value.push(vacancy)
  },
  setJobReview (state, job: MyCustomerJobListItem) {
    const archiveJobs = state.customerArchiveJobs.value.slice(0)
    const foundedJob = archiveJobs.find((j: MyCustomerJobListItem) => j.id === job.id)
    if (foundedJob) {
      foundedJob.hasReview = true
      state.customerArchiveJobs.loaded(archiveJobs)
    }
  },
  setFreelancerGigPublish (state, gig: MyGigListItem) {
    const draftGigs = state.freelancerDraftGigs.value.slice(0)
    const gigIndex = draftGigs.findIndex((g: MyGigListItem) => g.id === gig.id)
    draftGigs.splice(gigIndex, 1)
    state.freelancerDraftGigs.loaded(draftGigs)
    gig.status = GigStatuses.PUBLISHED
    state.freelancerActiveGigs.value.push(gig)
  },
  setFreelancerGigUnpublish (state, gig: MyGigListItem) {
    const activeGigs = state.freelancerActiveGigs.value.slice(0)
    const gigIndex = activeGigs.findIndex((g: MyGigListItem | MyGigApplication | GigOfferListItem) => (g as MyGigListItem).id === gig.id)
    activeGigs.splice(gigIndex, 1)
    state.freelancerActiveGigs.loaded(activeGigs)
    gig.status = GigStatuses.DRAFT
    state.freelancerDraftGigs.value.push(gig)
  },
  setFreelancerGigDelete (state, { gig, type }: { gig: MyGigListItem, type: string }) {
    const mapper = new Map([
      ['active', state.freelancerActiveGigs],
      ['draft', state.freelancerDraftGigs],
    ])
    const gigs = mapper.get(type)?.value.slice(0)
    if (gigs) {
      const gigIndex = gigs.findIndex((g: any) => g.id === gig.id)
      gigs.splice(gigIndex, 1)
      mapper.get(type)?.loaded(gigs)
    }
  },
} as MutationTree<IDashboardState>
