import { ModerationStages } from '@/constants/backend/ModerationStages'
import {
  STAGE_COMPLETED,
  STAGE_DEADLINE_OVERDUE,
  STAGE_DISPUTED,
  STAGE_ARCHIVED,
} from '@/constants/job/jobStages'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import { IDashboardState } from './types'
import LoadableModel from '@/models-ts/LoadableModel'

export function getInitialState (): IDashboardState {
  return {
    profileInfo: new LoadableModel({ isLoading: false, value: {} }),

    customerActiveJobs: new LoadableModel({ value: [] }),
    customerArchiveJobs: new LoadableModel({ value: [] }),
    customerDraftJobs: new LoadableModel({ value: [] }),
    customerActiveGigs: new LoadableModel({ value: [] }),
    customerArchiveGigs: new LoadableModel({ value: [] }),
    customerActiveVacancies: new LoadableModel({ value: [] }),
    customerArchiveVacancies: new LoadableModel({ value: [] }),
    customerDraftVacancies: new LoadableModel({ value: [] }),

    freelancerActiveVacancies: new LoadableModel({ value: [] }),
    freelancerArchiveVacancies: new LoadableModel({ value: [] }),
    freelancerActiveJobs: new LoadableModel({ value: [] }),
    freelancerArchiveJobs: new LoadableModel({ value: [] }),
    freelancerActiveGigs: new LoadableModel({ value: [] }),
    freelancerDraftGigs: new LoadableModel({ value: [] }),
    freelancerArchiveGigs: new LoadableModel({ value: [] }),
  }
}

export function isArchiveJob (job: MyCustomerJobListItem) {
  const ARCHIVE_STAGES = [STAGE_DISPUTED, STAGE_COMPLETED, STAGE_DEADLINE_OVERDUE, STAGE_ARCHIVED]
  return job.moderationStage === ModerationStages.FAILED || ARCHIVE_STAGES.includes(job.stage)
}
