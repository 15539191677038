import BigNumber from 'bignumber.js'
import { getWeb3Async } from '@/servicies/blockchain/web3'
import {
  getERC20AbiAsync,
} from '@/servicies/blockchain/artifactsAndAdresses'
import { CURRENCIES } from '@/constants/currency'
import { Blockchain, ChainId, EVM_BLOCKCHAIN } from '@/constants/blockchain'

let erc20Map = {
  ...EVM_BLOCKCHAIN.reduce((accum, blockchain) => ({
    ...accum,
    [`blockchain_${blockchain}`]: {}
  }), {}),
  ...EVM_BLOCKCHAIN.reduce((accum, blockchain) => ({
    ...accum,
    [`chainId_${ChainId[blockchain]}`]: {}
  }), {}),
}

// ------------- ERC20 tokens ------------------
export async function getErc20ContractAsync ({ blockchain = Blockchain.Ethereum, currencyName = 'USDT', chainId, erc20Address }) {
  const web3 = await getWeb3Async({ blockchain, chainId })
  const artifacts = await getERC20AbiAsync(currencyName)
  const address = erc20Address || CURRENCIES.find(
    item => item.name === currencyName && item.blockchain === blockchain).address
  if (chainId) {
    if (!erc20Map[`chainId_${chainId}`][address]) {
      erc20Map[`chainId_${chainId}`][address] = new web3.eth.Contract(artifacts, address)
    }
    return erc20Map[`chainId_${chainId}`][address]
  }
  if (blockchain) {
    if (!erc20Map[`blockchain_${blockchain}`][address]) {
      erc20Map[`blockchain_${blockchain}`][address] = new web3.eth.Contract(artifacts, address)
    }
    return erc20Map[`blockchain_${blockchain}`][address]
  }
}

export async function balanceOf ({ blockchain = Blockchain.Ethereum, chainId, owner, currencyName = 'USDT', erc20Address }) {
  const contract = await getErc20ContractAsync({ blockchain, currencyName, chainId, erc20Address })
  const value = await contract.methods.balanceOf(owner).call()
  if (value === null) {
    return new BigNumber(0)
  }
  return value
}
