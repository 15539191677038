import * as routes from '@/constants/routes'
import lazyLoadPage from '../lazyLoadPage'

export default [
  {
    path: '/jobs/edit/id:id(\\d+)',
    name: routes.JOB_EDIT,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-jobs" */ '@/pages/Jobs/JobAddEdit/JobAddEdit.vue')),
    meta: {
      requiresCustomer: true,
    }
  },
  {
    path: '/dashboard',
    name: routes.DASHBOARD,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Dashboard/Dashboard.vue')),
  },
  {
    path: '/notifications',
    name: routes.USER_NOTIFICATIONS,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Notifications/Notifications.vue')),
  },
  {
    path: '/profile-settings',
    name: routes.PROFILE_SETTINGS,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/ProfileSettings/ProfileSettings.vue')),
  },
  {
    path: '/settings',
    redirect: { name: routes.SECURITY },
    name: routes.SETTINGS,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Settings/Settings.vue')),
    children: [
      {
        path: 'security',
        name: routes.SECURITY,
      },
      {
        path: 'notifications',
        name: routes.NOTIFICATIONS,
      },
    ]
  },
  {
    path: '/wallets',
    name: routes.WALLETS,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Wallets/Wallets.vue')),
  },
  {
    path: '/referrals',
    name: routes.REFERRALS,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Referrals/Referrals.vue')),
  },
  {
    path: '/jobs/my/id:id(\\d+)',
    name: routes.JOB_DETAILS_ADMIN,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "public-page-jobs" */ '@/pages/Jobs/JobDetails/JobDetailsAdmin/JobDetailsAdmin.vue')
    ),
    children: [
      {
        path: 'description',
        name: routes.JOB_DETAILS_ADMIN_DESCRIPTION,
      },
      {
        path: 'applications',
        name: routes.JOB_DETAILS_ADMIN_APPLICATIONS,
      },
    ]
  },
  {
    path: '/jobs/my',
    name: routes.MY_JOBS,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Jobs/MyJobs/MyJobs.vue')),
    children: [
      {
        path: 'negotiations',
        name: routes.JOBS_NEGOTIATIONS,
        meta: {
          requiresFreelancer: true,
          redirectRoute: routes.JOBS_POSTED,
        },
      },
      {
        path: 'archive',
        redirect: { name: routes.JOBS_FINISHED },
      },
      {
        path: 'finished',
        name: routes.JOBS_FINISHED,
        meta: {
          requiresFreelancer: true,
          redirectRoute: routes.JOBS_POSTED,
        },
      },
      {
        path: 'to-do',
        name: routes.JOBS_TO_DO,
        meta: {
          requiresFreelancer: true,
          redirectRoute: routes.JOBS_IN_PROGRESS,
        },
      },
      {
        path: 'in-progress',
        name: routes.JOBS_IN_PROGRESS,
        meta: {
          requiresCustomer: true,
          redirectRoute: routes.JOBS_TO_DO,
        },
      },
      {
        path: 'all',
        name: routes.JOBS_ALL,
      },
      {
        path: 'completed',
        name: routes.JOBS_COMPLETED,
        meta: {
          requiresCustomer: true,
          redirect: routes.JOBS_FINISHED
        }
      },
      {
        path: 'posted',
        name: routes.JOBS_POSTED,
        meta: {
          requiresCustomer: true,
          redirectRoute: routes.JOBS_OFFERS,
        },
      },
    ]
  },
  {
    path: '/jobs/id:id(\\d+)/chat',
    name: routes.JOB_CHAT_BY_ID,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/JobChat/JobChat.vue')),
    meta: {
      layoutProps: {
        classes: 'job-chat-layout'
      }
    }
  },
  {
    path: '/jobs/:slug-:id(\\d+)/chat',
    name: routes.JOB_CHAT,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/JobChat/JobChat.vue')),
    meta: {
      layoutProps: {
        classes: 'job-chat-layout'
      }
    }
  },
  {
    path: '/gigs/my',
    name: routes.SERVICE_MY,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Gigs/MyGigs/MyGigs.vue')),
    children: [
      {
        path: 'posted',
        name: routes.SERVICE_MY_POSTED,
        meta: {
          requiresFreelancer: true,
          redirectRoute: routes.SERVICE_MY_OFFERS,
        },
      },
      {
        path: 'drafts',
        name: routes.SERVICE_MY_DRAFTS,
        meta: {
          requiresFreelancer: true,
          redirectRoute: routes.SERVICE_MY_OFFERS,
        },
      },
      {
        path: 'archived',
        name: routes.SERVICE_MY_ARCHIVED,
      },
      {
        path: 'offers',
        name: routes.SERVICE_MY_OFFERS,
      },
      {
        path: 'in-progress',
        name: routes.SERVICE_MY_IN_PROGRESS,
      },
      {
        path: 'completed',
        name: routes.SERVICE_MY_COMPLETED,
      },
    ],
  },
  {
    path: '/gigs/edit/id:id(\\d+)',
    name: routes.SERVICE_EDIT,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-gigs" */ '@/pages/Gigs/GigAddEdit/GigAddEdit.vue')),
    meta: {
      requiresFreelancer: true,
    },
  },

  {
    path: '/gigs/id:id(\\d+)/chat',
    name: routes.SERVICE_CHAT_BY_ID,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/GigChat/GigChat.vue')),
    meta: {
      layoutProps: {
        classes: 'gig-chat-layout'
      }
    }
  },
  {
    path: '/gigs/:slug-:id(\\d+)/chat',
    name: routes.SERVICE_CHAT,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/GigChat/GigChat.vue')),
    meta: {
      layoutProps: {
        classes: 'gig-chat-layout'
      }
    }
  },
  {
    path: '/vacancies/my/id:id(\\d+)',
    name: routes.VACANCY_DETAILS_ADMIN,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "public-page-jobs" */ '@/pages/Vacancies/VacancyDetailsNew/VacancyDetailsAdmin/VacancyDetailsAdmin.vue')
    ),
    children: [
      {
        path: 'description',
        name: routes.VACANCY_DETAILS_ADMIN_DESCRIPTION,

      },
      {
        path: 'applications',
        name: routes.VACANCY_DETAILS_ADMIN_APPLICATIONS,
      },
      {
        path: 'ai-recommended',
        name: routes.VACANCY_DETAILS_ADMIN_AI_RECOMMENDED,
      },
    ]
  },
  {
    path: '/vacancies/my',
    name: routes.VACANCIES_MY,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "private-pages" */ '@/pages/Vacancies/MyVacancies/MyVacancies.vue')
    ),
    children: [
      {
        path: 'published',
        name: routes.VACANCIES_MY_PUBLISHED,
        meta: {
          requiresCustomer: true,
          redirectRoute: routes.SERVICE_MY,
        },
      },
      {
        path: 'drafts',
        name: routes.VACANCIES_MY_DRAFTS,
        meta: {
          requiresCustomer: true,
          redirectRoute: routes.SERVICE_MY,
        },
      },
      {
        path: 'archived',
        name: routes.VACANCIES_MY_ARCHIVED,
      },
      {
        path: 'all',
        name: routes.VACANCIES_MY_ALL,
        meta: {
          requiresFreelancer: true,
          redirectRoute: routes.MY_JOBS,
        },
      },
      {
        path: 'applied',
        name: routes.VACANCIES_MY_APPLIED,
        meta: {
          requiresFreelancer: true,
          redirectRoute: routes.MY_JOBS,
        },
      },
      {
        path: 'in-progress',
        name: routes.VACANCIES_MY_IN_PROGRESS,
        meta: {
          requiresFreelancer: true,
          redirectRoute: routes.MY_JOBS,
        },
      },
    ]
  },

  {
    path: '/vacancies/edit/id:id(\\d+)',
    name: routes.VACANCY_EDIT,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/AddEditVacancyNew/AddEditVacancyNew.vue')
    ),
    meta: {
      requiresCustomer: true,
    }
  },
  {
    path: '/vacancies/id:id(\\d+)/chat',
    name: routes.VACANCY_CHAT_BY_ID,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/VacancyChat/VacancyChat.vue')),
    meta: {
      layoutProps: {
        classes: 'vacancy-chat-layout'
      }
    }
  },
  {
    path: '/vacancies/:slug-:id(\\d+)/chat',
    name: routes.VACANCY_CHAT,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/VacancyChat/VacancyChat.vue')),
    meta: {
      layoutProps: {
        classes: 'vacancy-chat-layout'
      }
    }
  },
  {
    path: '/chat',
    name: routes.CHAT,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/ChatRooms/ChatRooms.vue')
    ),
    children: [
      {
        path: 'freelance',
        name: routes.CHAT_FREELANCE,
        component: () => lazyLoadPage(
          import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/ChatRooms/ChatRooms.vue')
        ),
      },
      {
        path: 'full-time',
        name: routes.CHAT_FULL_TIME,
        component: () => lazyLoadPage(
          import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/ChatRooms/ChatRooms.vue')
        ),
      },
      {
        path: 'archived',
        name: routes.CHAT_ARCHIVED,
        component: () => lazyLoadPage(
          import(/* webpackChunkName: "private-pages" */ '@/pages/Chat/ChatRooms/ChatRooms.vue')
        ),
      },
    ]
  },
  {
    path: '/bookmarks',
    name: routes.BOOKMARKS,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "private-pages" */ '@/pages/Bookmarks/Bookmarks.vue')
    ),
    children: [
      {
        path: '/bookmarks/gigs',
        name: routes.GIG_BOOKMARKS,
        component: () => lazyLoadPage(
          import(/* webpackChunkName: "private-pages" */ '@/pages/Bookmarks/Bookmarks.vue')
        ),
      },
      {
        path: '/bookmarks/jobs',
        name: routes.JOB_BOOKMARKS,
        component: () => lazyLoadPage(
          import(/* webpackChunkName: "private-pages" */ '@/pages/Bookmarks/Bookmarks.vue')
        ),
      },
      {
        path: '/bookmarks/vacancies',
        name: routes.VACANCY_BOOKMARKS,
        component: () => lazyLoadPage(
          import(/* webpackChunkName: "private-pages" */ '@/pages/Bookmarks/Bookmarks.vue')
        ),
      },
    ]
  },
  // {
  //   path: '/transactions',
  //   name: routes.TRANSACTIONS,
  //   component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/TransactionHistory/TransactionHistory.vue')),
  // },
]
