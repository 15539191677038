import Vue from 'vue'
import Router from 'vue-router'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import privateRoutes from './routes/private'
import publicRoutes from './routes/public'
import otherRoutes from './routes/other'
import animateScrollTo from '@/utils/animateScrollTo'
import * as routes from '@/constants/routes'

Vue.use(Router)

const checkUser = ({ store, route, redirect }) => {
  if (!store.state.app.authorized) {
    redirect(`/?redirect=${route.fullPath}`)
  }
}
const markAuth = (route) => ({ ...route, meta: { requiresAuth: true, middlewares: [checkUser], ...route.meta } })

const skipLocationChangeLogging = (to, from) => {
  const tabPages = [
    [routes.JOB_DETAILS_ADMIN, routes.JOB_DETAILS_ADMIN_DESCRIPTION, routes.JOB_DETAILS_ADMIN_APPLICATIONS],
    [
      routes.VACANCY_DETAILS_ADMIN,
      routes.VACANCY_DETAILS_ADMIN_DESCRIPTION,
      routes.VACANCY_DETAILS_ADMIN_APPLICATIONS,
      routes.VACANCY_DETAILS_ADMIN_AI_RECOMMENDED,
    ],
    [routes.BOOKMARKS, routes.GIG_BOOKMARKS, routes.JOB_BOOKMARKS, routes.VACANCY_BOOKMARKS],
  ]
  const page = tabPages.find(p => p.includes(to.name))
  return page?.includes(from?.name)
}

export default (store) => {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      ...privateRoutes.map(route => ({
        ...markAuth(route),
        children: (route.children || []).map(markAuth),
      })),
      ...publicRoutes,
      ...otherRoutes,
    ],
    scrollBehavior (to, from, savedPosition) {
      if (to.hash) {
        const el = document.getElementById(CSS.escape(to.hash.slice(1)))
        if (el) {
          return animateScrollTo(el)
        }
        return { selector: to.hash }
      }
      if (savedPosition) {
        return savedPosition
      }
      if (to.params.savePosition) return {}
      if (to.meta.containsTabs && to.name === from.name) {
        return { x: 0, y: window.scrollY }
      }
      return { x: 0, y: 0 }
    },
  })

  router.afterEach((to, from) => {
    if (process.client) {
      sendAnalytics(to, from)
    }
    if (from?.name && !skipLocationChangeLogging(to, from)) {
      store.commit('app/setPreviousRoute', { name: from.name, params: from.params, query: from.query })
    }
  })

  return router
}

function sendAnalytics (to, from) {
  googleAnalyticsV2.send({
    event: 'pageview',
    url: to.fullPath,
    location: window.location.href,
  })
}
