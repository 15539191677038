import Vue from 'vue'
// @ts-ignore
import { mixin as clickaway } from 'vue-clickaway'

export default Vue.extend<any, any, any, any>({
  mixins: [clickaway],
  props: {
    title: String
  },
  data () {
    return {
      open: false,
    }
  },
  watch: {
    '$route': {
      handler () {
        this.open = false
      }
    }
  },
  methods: {
    onClickAway () {
      if (this.open) {
        this.open = false
      }
    }
  }
})
