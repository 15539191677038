import LoadableModel from '@/models-ts/LoadableModel'
import { MutationTree } from 'vuex'
import { ISkillsState } from './types'
import Skill from '@/models-ts/Skill'

export default {
  beforeReady (state) {
    state.skills = new LoadableModel({
      ...state.skills,
      value: state.skills.value?.map(Skill.fromServer) || [],
    })
    state.deprecatedSkills = state.deprecatedSkills?.map(Skill.fromServer) || []
  },
  setSkillsLoading: (state) => {
    state.skills.loading()
  },
  setSkillsLoaded: (state, skills) => {
    state.skills.loaded(skills)
  },
  setDeprecatedSkills: (state, skills) => {
    state.deprecatedSkills = skills
  },
  setSkillDetails: (state, skill) => {
    state.skillDetails = skill
  },
} as MutationTree<ISkillsState>
