import { VNode } from 'vue'

export default {
  inserted (el: HTMLElement, binding: any, vnode: VNode) {
    const store = vnode.context?.$store
    const isLoggedIn = store?.state.app.authorized
    if (el.classList.contains('public-page') && isLoggedIn) {
      el.classList.remove('public-page')
    } else if (!el.classList.contains('public-page') && !isLoggedIn) {
      el.classList.add('public-page')
    }
  }
}
