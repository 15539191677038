import cloneDeep from 'lodash/cloneDeep'

export type JobApplicationReviewFromServer = {
  id: number
  from_user_id: number
  to_user_id: number
  type: number
  gig_job_id?: number
  job_id?: number
  text: string
  created_at: string
  updated_at: string
}

export class JobApplicationReview {
  id: number
  fromUserId: number
  toUserId: number
  type: number
  gigJobId?: number
  jobId?: number
  text: string

  constructor (data: Partial<JobApplicationReview>) {
    Object.assign(this, cloneDeep(data))
  }

  static fromServer (data: JobApplicationReviewFromServer) {
    return new JobApplicationReview({
      id: data.id,
      fromUserId: data.from_user_id,
      toUserId: data.to_user_id,
      type: data.type,
      gigJobId: data.gig_job_id,
      jobId: data.job_id,
      text: data.text
    })
  }
}
