import { AccountTypes } from '@/constants/user/accountTypes'
import cloneDeep from 'lodash/cloneDeep'
import { Avatar } from '@/models/user'
import { UserInfoFromServer } from '@/models-ts/user/UserInfo'
import File, { FileFromServer } from '@/models-ts/File'

export default class AiTalentRecommendation {
  id: number
  bio: string
  type: AccountTypes
  name: string
  avgReputation: number
  avgReviews: number
  reviewsCount: number
  avatar: Avatar
  cv: Array<File>

  constructor (data: Partial<AiTalentRecommendation>) {
    Object.assign(this, cloneDeep(data))
  }

  static fromServer (data: AiTalentRecommendationFromServer) {
    return new AiTalentRecommendation({
      id: data.relations.Freelancer.id,
      bio: data?.bio,
      type: data.relations.Freelancer.type,
      name: data.relations.Freelancer.name,
      avgReputation: data.relations.Freelancer?.rating?.avg_reputation,
      avgReviews: data.relations.Freelancer?.rating?.avg_reviews,
      reviewsCount: data.relations.Freelancer?.reviews_count,
      avatar: Avatar.fromServer(data.relations.Freelancer?.avatar),
      cv: (data.relations.Cv || []).map(File.fromServer),
    })
  }
}

export type AiTalentRecommendationFromServer = {
  bio: string
  fileLink?: string
  relations: {
    Cv: Array<FileFromServer> | null
    Freelancer: UserInfoFromServer
  }
}
