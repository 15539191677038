import { Commit } from 'vuex'
import Rate from '@/models-ts/Rate'
import LoadableModel from '@/models-ts/LoadableModel'
import { getRates } from '@/api/rates'
import { IRatesState } from './types'

const getInitialState = (): IRatesState => ({
  rates: new LoadableModel({ isLoading: false, value: [] }),
})

export default () => ({
  namespaced: true,
  state: getInitialState(),
  mutations: {
    beforeReady (state: IRatesState) {
      state.rates = new LoadableModel<Array<Rate>>(state.rates)
    },
    setRatesLoading (state: IRatesState) {
      state.rates.loading()
    },
    setRatesLoaded (state: IRatesState, rates: Array<Rate>) {
      state.rates.loaded(rates)
    },
  },
  actions: {
    async getRates ({ commit, state }: { commit: Commit, state: IRatesState }, forceUpdate: boolean) {
      if (state.rates.isLoading) {
        const result = await state.rates.promise?.promise
        return result
      }
      if (!forceUpdate && state.rates.value && state.rates.value.length > 0) {
        return state.rates.value
      }
      commit('setRatesLoading')
      let rates = await getRates()
      rates = rates.map((rate: Rate) => new Rate(rate))
      commit('setRatesLoaded', rates)
      return rates
    },
  }
})
