import Currency from '@/models-ts/Currency'
import { CURRENCY_FIELD_NAME } from '@/constants/currency'
import { getCurrency } from '@/utils-ts/currencies'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'lx-currency',
  props: {
    currency: Object,
    name: String,
    blockchain: [String, Number],
    short: Boolean,
  },
  computed: {
    model (): Currency | undefined {
      if (this.currency) return this.currency
      return getCurrency({ blockchain: Number(this.blockchain), value: this.name, field: CURRENCY_FIELD_NAME })
    },
    title (): string {
      return (this.short ? this.model?.name : this.model?.displayName) || this.name
    },
    image (): string {
      return this.model?.imageURL || '/static/images/blockchain/currencies/empty.svg'
    },
    circle (): boolean {
      return this.model?.name === 'BRUSH'
    },
  },
})
