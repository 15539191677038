import { render, staticRenderFns } from "./NoContent.htm?vue&type=template&id=54c71d65&scoped=true&"
import script from "./NoContent.ts?vue&type=script&lang=js&"
export * from "./NoContent.ts?vue&type=script&lang=js&"
import style0 from "./NoContent.scss?vue&type=style&index=0&id=54c71d65&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c71d65",
  null
  
)

export default component.exports