import { render, staticRenderFns } from "./Currency.htm?vue&type=template&id=6282b450&scoped=true&"
import script from "./Currency.ts?vue&type=script&lang=js&"
export * from "./Currency.ts?vue&type=script&lang=js&"
import style0 from "./Currency.scss?vue&type=style&index=0&id=6282b450&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6282b450",
  null
  
)

export default component.exports