import { AccountTypes } from '@/constants/user/accountTypes'
import { CustomerTypes } from '@/constants/user/roles'
import { Avatar } from '@/models/user'

export default class UserInfo {
  id: number
  name: string
  type: AccountTypes
  avatar: Avatar
  reviewsCount: number
  avgReviews: string
  is_customer_verified: boolean
  customerType?: CustomerTypes

  constructor (data: UserInfo) {
    Object.assign(this, data)
  }

  static fromServer (data: UserInfoFromServer) {
    return new UserInfo({
      id: data.id,
      name: data.name,
      avatar: Avatar.fromServer(data.avatar),
      type: data.type,
      avgReviews: (data.rating.avg_reviews || 0).toFixed(2),
      reviewsCount: data.reviews_count,
      is_customer_verified: Number(data.is_customer_verified) === 1,
      customerType: data.profile?.type,
    })
  }
}

export type UserInfoFromServer = {
  id: number
  name: string
  type: AccountTypes
  avatar: Array<{
    url: string,
    category: number,
    meta?: { width: number, height: number }
  }>
  rating: {
    avg_reputation?: number
    avg_reviews: number
  }
  reviews_count: number
  is_customer_verified?: 0 | 1 | '0' | '1' | null | boolean
  meta?: {}
  profile?: {
    created_at?: string
    website?: string | null
    type: CustomerTypes
  }
}
