import cloneDeep from 'lodash/cloneDeep'
import unescape from 'lodash/unescape'
import get from 'lodash/get'
import omit from 'lodash/omit'
import VacancyRole from '@/models-ts/vacancies/VacancyRole'
import Skill, { SkillFromServer } from '@/models-ts/Skill'
import { PositionType } from '@/constants/vacancies/positionTypes'
import { Stages, Statuses } from '@/constants/vacancies/statuses'
import { WorkExperience } from '@/constants/vacancies/experiences'
import { VacancyModerationStages } from '@/constants/vacancies/vacancyModerationStages'
import { parseSlug } from '@/utils-ts/parser'
import FreelancerListItem, { FreelancerListItemServerProps } from '@/models-ts/user/FreelancerListItem'
import VacancyRecruiter, { VacancyRecruiterFromServer } from './VacancyRecruiter'
import VacancyApplicationItem, { VacancyApplicationFromServer } from './VacancyApplicationItem'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import VacancyLocation, { VacancyLocationFromServer } from './VacancyLocation'

export default class Vacancy {
  id: number | null
  slug: string | null
  status: Statuses | null
  stage: Stages | null
  customer_id: number | null
  name: string
  description : string
  position_office: boolean
  position_remote: boolean
  position_type: PositionType | null
  moderation_stage?: VacancyModerationStages | null
  payments_is_crypto: boolean
  salary_type: number
  salary_from: number
  salary_to: number
  work_experience: WorkExperience | null
  primaryRole: VacancyRole['id'] | null
  primaryRoleName: VacancyRole['name'] | null
  external_url: string | null
  applicationForm: Array<ApplicationFormField>
  applicationFormEnabled: boolean
  isCvMatchFeedbackSent: boolean
  relations: {
    Skill: Array<Skill>
    Location: Array<VacancyLocation>
    Offer: Array<{}>
    Customer: VacancyRecruiter | null
  }
  applications: Array<VacancyApplicationItem>
  bookmarkId?: number | null
  category?: Skill | null
  relatedFreelancers: Array<FreelancerListItem> | null

  constructor (data: Partial<Vacancy> | void) {
    Object.assign(this, cloneDeep({
      id: null,
      slug: null,
      status: null,
      stage: null,
      customer_id: null,
      name: '',
      description: '',
      position_office: false,
      position_remote: false,
      position_type: null,
      payments_is_crypto: false,
      salary_type: SalaryTypes.RANGE,
      salary_from: 0,
      salary_to: 0,
      work_experience: WorkExperience.NOT_SET,
      primaryRole: null,
      primaryRoleName: null,
      external_url: null,
      applications: [],
      relations: {
        Skill: [],
        Location: [],
        Offer: [],
        Customer: null,
      },
      ...data,
    }))
  }

  static fromServer ({ additional_applications_form, additional_applications_form_is_enabled, ...data }: VacancyFromServer) {
    const primaryRole = get(data.relations, 'PrimaryRole[0]', { id: null, name: null })
    return new Vacancy({
      ...data,
      name: unescape(data.name),
      slug: parseSlug(data.slug),
      position_office: Boolean(data.position_office),
      position_remote: Boolean(data.position_remote),
      payments_is_crypto: Boolean(data.payments_is_crypto),
      salary_from: Number(data.salary_from || 0),
      salary_to: Number(data.salary_to || 0),
      primaryRole: primaryRole.id,
      primaryRoleName: primaryRole.name,
      external_url: data.external_url,
      applicationForm: additional_applications_form || [],
      applicationFormEnabled: additional_applications_form_is_enabled,
      isCvMatchFeedbackSent: Boolean(data.is_cv_match_feedback_sent),
      relations: {
        Customer: VacancyRecruiter.fromServer(data.relations.Customer),
        Skill: (data.relations.Skill || []).map(Skill.fromServer),
        Location: (data.relations.Location || []).map(VacancyLocation.fromServer),
        Offer: (data.relations.Offer || [])
      },
      bookmarkId: get(data, 'meta.bookmarks[0].id', null),
      category: data.meta?.category,
      applications: (data.relations.Application || []).map(VacancyApplicationItem.fromServer),
      relatedFreelancers: (data.meta?.related?.freelancers || []).map(FreelancerListItem.fromServer),
    })
  }

  toServer () {
    const { primaryRole, payments_is_crypto, position_office, position_remote, salary_to, status, ...payload } = omit(this, 'country_id')
    return {
      ...payload,
      payments_is_crypto: Number(payments_is_crypto),
      position_office: Number(position_office),
      position_remote: Number(position_remote),
      salary_to: this.salary_type === SalaryTypes.FIXED ? this.salary_from : this.salary_to,
      relations: {
        Location: payload.relations.Location,
        Skill: payload.relations.Skill.map(({ id }) => ({ id })),
        PrimaryRole: primaryRole ? [{ id: primaryRole }] : [],
      }
    }
  }

  get hasCompensation () {
    return this.salary_from > 0 || this.salary_to > 0
  }

  get remoteInfo () {
    if (this.position_remote) {
      return this.position_office ? 'Hybrid' : 'Remote'
    }
    return 'Office'
  }
}

export type VacancyFromServer = {
  id: number
  slug: string
  is_removed: 0 | 1
  customer_id: number
  status: Statuses,
  stage: Stages,
  name: string
  description : string
  position_office: number
  position_remote: number
  position_type: PositionType
  moderation_stage?: VacancyModerationStages | null
  payments_is_crypto: number
  salary_type: number
  salary_from: string
  salary_to: string
  work_experience: WorkExperience
  external_url: string | null
  additional_applications_form?: Array<ApplicationFormField> | null
  additional_applications_form_is_enabled: boolean
  is_cv_match_feedback_sent: 0 | 1
  relations: {
    Application?: Array<VacancyApplicationFromServer>
    Skill: Array<SkillFromServer>
    Location: Array<VacancyLocationFromServer>
    PrimaryRole: Array<Required<VacancyRole>>
    Customer: VacancyRecruiterFromServer
    Offer?: Array<{}>
  }
  meta: {
    bookmarks: Array<{ id: number }>
    category?: Skill
    related?: {
      freelancers: Array<FreelancerListItemServerProps>
    } | null
  } | null
}

export interface ApplicationFormField {
  group: string | null
  technical: {
    uuid: string
  }
  label?: string | null
  option?: { title: string, value: string } | null
  options?: Array<{ title: string, value: string }> | null
  type: 'hidden' | 'input' | 'select' | 'checkbox' | 'textarea' | 'section' | 'radio' | 'file_upload'
  validations?: {
    maxlength?: number | null
    required?: boolean
  }
  value?: string | null
  addons?: ApplicationFormField | null
}
