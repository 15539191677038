import Cookies from 'js-cookie'
import { cryptoAuth, signIn, signOut } from '@/api/usersAndProfiles/auth'
import { AppState } from './types'
import { RootState } from '@/store'
import { Module } from 'vuex'

export default (): Module<AppState, RootState> => ({
  namespaced: true,
  state: {
    authorized: false,
    redirect: '',
    notFound: false,
    previousRoute: null,
  },
  mutations: {
    setAuthorized (state, flag) {
      state.authorized = flag
    },
    setNotFound: (state, flag) => {
      state.notFound = flag
    },
    setRedirect: (state, path) => {
      state.redirect = path
    },
    setPreviousRoute: (state, from) => {
      state.previousRoute = from
    },
  },
  actions: {
    setToken ({ commit }, token) {
      commit('setAuthorized', !!token)
      if (process.client) {
        if (token) {
          Cookies.set('accessToken', String(Boolean(token)), {
            expires: 30,
            samesite: 'lax',
            secure: process.env.NODE_ENV === 'production',
          })
        } else {
          Cookies.remove('accessToken')
        }
        commit('user/setToken', token, { root: true })
      }
    },
    async login ({ dispatch }, { email, password, reCaptcha, key }) {
      const { token } = await signIn({ login: email, password, reCaptcha, key })
      return dispatch('setToken', token)
    },
    async cryptoLogin ({ dispatch }, { reCaptcha, ...payload }) {
      const response = await cryptoAuth(payload, reCaptcha)
      dispatch('setToken', response?.token)
      return response?.redirect_url
    },
    async logout ({ commit, dispatch }, isLeaveState) {
      try {
        commit('setAuthorized', false)
        await signOut()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch('setToken', null)
        dispatch('resetState', isLeaveState, { root: true })
      }
    },
  }
})
