export default {
  name: 'lx-share-this-socials',
  props: {
    facebook: String,
    twitter: String,
    telegram: String,
    linkedin: String,
    onClick: Function,
  },
}
