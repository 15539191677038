import { BACKEND_PRIVATE } from '../base'

type BackendResponse<T> = Promise<{ response: T }>

export async function generateJobDescription (title: string, requiredSkills: Array<string>) {
  return BACKEND_PRIVATE.post('/me/ai/jobs/generate-description', {
    payload: {
      title,
      requiredSkills,
    }
  })
}

export async function getJobDescription (id: string): BackendResponse<{ text: string }> {
  return BACKEND_PRIVATE.get('/me/ai/jobs/get-job', { params: { id } })
}

export async function getAiRecommendedListFreelancers (vacancyId: string) {
  return BACKEND_PRIVATE.get('/me/ai/recommendation/list-freelancers-by-vacancy', {
    params: { id: vacancyId }
  })
}

export async function feedbackByVacancy (vacancyId: string, score: 0 | 1, comment: string) {
  return BACKEND_PRIVATE.post('/me/ai/recommendation/feedback-by-vacancy', {
    payload: {
      vacancyId,
      score,
      comment
    }
  })
}

export async function generateByVacancy (vacancyId: string) {
  return BACKEND_PRIVATE.post('/me/ai/recommendation/generate-by-vacancy', null, {
    params: { id: vacancyId }
  })
}
