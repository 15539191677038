export enum JobOfferStages {
  NEW = 1,
  DECLINED_BY_CUSTOMER = 2,
  ACCEPTED_BY_CUSTOMER = 3,
  DECLINED_BY_FREELANCER = 4,
}

export enum JobOfferStagesInChat {
  NEW = 1,
  DECLINED_BY_FREELANCER = 2,
  DECLINED_BY_CUSTOMER = 3,
  ACCEPTED_BY_CUSTOMER = 4,
}
