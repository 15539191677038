export const USER_ACCOUNT_SIMPLE = 1
export const USER_ACCOUNT_PREMIUM_BRONZE = 2
export const USER_ACCOUNT_PREMIUM_SILVER = 3
export const USER_ACCOUNT_PREMIUM_GOLD = 4
export const USER_ACCOUNT_PREMIUM_PLATINUM = 5

export const ACCOUNT_TYPES = [
  USER_ACCOUNT_SIMPLE,
  USER_ACCOUNT_PREMIUM_BRONZE,
  USER_ACCOUNT_PREMIUM_SILVER,
  USER_ACCOUNT_PREMIUM_GOLD,
  USER_ACCOUNT_PREMIUM_PLATINUM,
]

export enum AccountTypes {
  USER_ACCOUNT_SIMPLE,
  USER_ACCOUNT_PREMIUM_BRONZE,
  USER_ACCOUNT_PREMIUM_SILVER,
  USER_ACCOUNT_PREMIUM_GOLD,
  USER_ACCOUNT_PREMIUM_PLATINUM,
}
