import { Blockchain } from './common'

export const SWAP_TOKENS_GAS_LIMIT_MULTIPLIER = 1.2
export const SWAP_TOKENS_GAS_PRICE_MULTIPLIER = 1.2
export const SWAP_GAS_LIMIT_FIXED = 400000

const NETWORK_ETHEREUM_FOR_SWAP = process.env.VUE_APP_NETWORK_ETHEREUM_FOR_SWAP
const NETWORK_BINANCE_FOR_SWAP = process.env.VUE_APP_NETWORK_BINANCE_FOR_SWAP
const NETWORK_POLYGON_FOR_SWAP = process.env.VUE_APP_NETWORK_POLYGON_FOR_SWAP
const NETWORK_FANTOM_FOR_SWAP = process.env.VUE_APP_NETWORK_FANTOM
const NETWORK_ARBITRUM_FOR_SWAP = process.env.VUE_APP_NETWORK_ARBITRUM
const NETWORK_BASE_FOR_SWAP = process.env.VUE_APP_NETWORK_BASE

export const NETWORK_IDS_FOR_SWAP = {
  [Blockchain.Ethereum]: NETWORK_ETHEREUM_FOR_SWAP,
  [Blockchain.Binance]: NETWORK_BINANCE_FOR_SWAP,
  [Blockchain.Polygon]: NETWORK_POLYGON_FOR_SWAP,
  [Blockchain.Fantom]: NETWORK_FANTOM_FOR_SWAP,
  [Blockchain.Arbitrum]: NETWORK_ARBITRUM_FOR_SWAP,
  [Blockchain.Base]: NETWORK_BASE_FOR_SWAP,
}
