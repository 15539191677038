import { SubcategoriesWithSkills } from '@/store/shared/modules/skills/types'
import Vue, { PropType } from 'vue'
import navigationSkills from '@/mixins/navigationSkills'
import Skill from '@/models-ts/Skill'

type SubcategoriesWithSkillsMap = Record<Skill['id'], SubcategoriesWithSkills[]>

export default Vue.extend<any, any, any, any>({
  mixins: [navigationSkills],
  props: {
    selectedCategoryId: Number as PropType<Skill['id']>,
    skillsMap: Object as PropType<SubcategoriesWithSkillsMap>,
  },
  computed: {
    selectedSkills () {
      const { [this.selectedCategoryId]: visibleSkills, ...notVisibleMap } = (this.skillsMap as SubcategoriesWithSkillsMap)
      const prepareSkills = (map: SubcategoriesWithSkills[], visible: boolean) => map
        ?.reduce((acc, s) => acc.concat(s), [] as SubcategoriesWithSkills[])
        ?.map(item => ({
          ...item,
          visible,
          skills: item.skills.map(s => ({ ...s, visible: true }))
        })) || []
      const visible = prepareSkills(visibleSkills, true)
      const notVisible = Object.values(notVisibleMap)
        .filter(arr => arr.length)
        .map(arr => prepareSkills(arr, false)).reduce((acc, s) => acc.concat(s))
      return [...visible, ...notVisible]
    },
    visibleList () {
      return this.selectedSkills.filter((s: { visible: boolean }) => s.visible)
    },
    onlyOther (): boolean {
      return this.visibleList.length === 1 && this.selectedSkills[0].subCategory === 'Other'
    },
  },
})
