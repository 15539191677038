<template>
  <qr-code
    class="root qr-code"
    :style="{ width: `${width}px`, height: `${width}px`}"
    v-bind="$attrs"
    v-on="$listeners"
    :options="{ width, margin }"
    :value="value"

  />
</template>

<script>
import VueTypes from 'vue-types'
import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue'
import ErrorMessage from '@/components/QRCode/ErrorMessage/ErrorMessage.vue'

const components = {}
if (process.client || typeof process.client === 'undefined') {
  components['QrCode'] = () => ({
    component: import(/* webpackChunkName: "qr-code" */ '@/components/QRCode/QRCode.vue'),
    loading: SkeletonLoader,
    error: ErrorMessage,
  })
}

export default {
  name: 'lx-qr-code',
  components,
  props: {
    value: String,
    width: Number,
    margin: {
      type: Number,
      default: 0
    }
  },
}
</script>
