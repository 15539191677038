import {
  generateByVacancy,
  generateJobDescription,
  getAiRecommendedListFreelancers,
  getJobDescription,
} from '@/api/jobs/aiAssistants'
import lxAnalytics from '@/servicies-ts/analytics/LxAnalytics'
import { AiTalentRecommendationFromServer } from '@/models-ts/vacancies/AiTalentRecommendation'
import ErrorMatcher from '@/utils/ErrorMatcher'

export class AIAssistantService {
  private interval: number
  private maxAttemptsRecommendations: number = 100

  async loadVacancyFreelancerRecommendations ({ vacancyId }: { vacancyId: string })
    : Promise<{ pagination: object, freelancers: Array<AiTalentRecommendationFromServer> } | null> {
    const checkRecommendations = async (_vacancyId: string) => {
      try {
        const data = await getAiRecommendedListFreelancers(_vacancyId)
        if (data?.freelancers) {
          return { pagination: data.pagination, freelancers: data.freelancers }
        }
      } catch (err) {
        if (ErrorMatcher.isNotFound(err)) {
          await generateByVacancy(_vacancyId)
        } else {
          console.error(err)
        }
      }
      return null
    }
    const startLoading = new Date()
    lxAnalytics.send(`vacancy-freelancers-recommendations-generate-start`, {
      vacancyId,
    })
    let data: { pagination: object, freelancers: Array<any> } | null = await checkRecommendations(vacancyId)
    if (data === null) {
      data = await new Promise<{ pagination: object, freelancers: Array<any> } | null>((resolve, reject) => {
        let attempts = 0
        this.interval = window.setInterval(async () => {
          if (attempts > this.maxAttemptsRecommendations) {
            this.destroy()
            reject(new Error('Reject max attempts'))
          }
          data = await checkRecommendations(vacancyId)
          if (data) {
            this.destroy()
            resolve(data)
          }
          attempts++
        }, 3000)
      })
    }
    lxAnalytics.send(`vacancy-freelancers-recommendations-generate-end`, {
      vacancyId,
      duration: Number(new Date()) - (Number(startLoading) || 0),
    })
    return data
  }

  async generateDescription (
    { name, skills, type }:
      { name: string, skills: Array<string>, type: 'job' | 'vacancy' },
  ): Promise<string> {
    const { id } = await generateJobDescription(name, skills)
    const startGeneration = new Date()
    lxAnalytics.send(`${type}-description-generate-start`, {
      id,
      title: name,
      skills: skills,
    })
    const description = await new Promise<string>(resolve => {
      this.interval = window.setInterval(async () => {
        const description = await this.loadAiDescription(id)
        if (description) {
          const text = this.prepareDescription(description)
          this.destroy()
          resolve(text)
        }
      }, 3000)
    })
    lxAnalytics.send(`${type}-description-generate-end`, {
      id,
      duration: Number(new Date()) - (Number(startGeneration) || 0),
    })
    return description
  }

  destroy () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = 0
    }
  }

  private async loadAiDescription (generationId: string) {
    const result = await getJobDescription(generationId)
    return result.response?.text
  }

  private prepareDescription (description: string) {
    description = description.replace(
      new RegExp(/^(\r|\n|\t| ){0,1}(Job overview|Job description|Job summary):?(\r|\n|\t| )+/, 'i'),
      '',
    )
    return description
      .split(new RegExp(/\r?\n/, 'g'))
      .filter(Boolean)
      .map((part: string) => `<p>${part}</p>`)
      .join('')
  }
}

export const aiAssistant = new AIAssistantService()
