import { RootState } from '@/store'
import Vue, { PropType } from 'vue'
import { RawLocation } from 'vue-router'
import { mapState } from 'vuex'

interface Computed {
  previousRoute?: RawLocation
}

interface Props {
  default?: RawLocation
}

export default Vue.extend<{}, {}, Computed, Props>({
  name: 'lx-back-link',
  props: {
    default: Object as PropType<RawLocation>
  },
  computed: {
    ...mapState<RootState>({
      previousRoute: (state: RootState) => state.app.previousRoute,
    }),
  },
  methods: {
    onClick () {
      if (this.previousRoute) {
        this.$router.push(this.previousRoute)
      } else if (this.default) {
        this.$router.push(this.default)
      } else {
        this.$router.go(-1)
      }
    }
  }
})
