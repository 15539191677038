import { ActionTree } from 'vuex'
import DashboardProfileInfo from '@/models-ts/dashboard/DashboardProfileInfo'
import { getProfileInfo } from '@/api/dashboard'
import { getMyCustomerJobList, getWorkerList } from '@/api/jobs/lists'
import {
  getFreelancerNegotiationsOffers,
  getCustomerCompleted,
  getCustomerInProgressOffers,
  getCustomerNegotiationsOffers,
  getMyServices,
  getFreelancerCompleted,
  getFreelancerInProgress,
} from '@/api/gig'
import { IDashboardState } from './types'
import MyGigApplication from '@/models-ts/gigs/MyGigApplication'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import {
  getMyFreelancerApplications,
  getMyPostedVacancies,
  getMyDraftsVacancies,
  getMyCompletedVacancies
} from '@/api/vacancies'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import { VacancyApplicationStatuses } from '@/constants/vacancies/vacancyApplicationStatuses'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { STATUS_DRAFT } from '@/constants/job/jobStatuses'
import MyGigListItem from '@/models-ts/gigs/MyGigListItem'
import { GigStatuses } from '@/constants/gig/gigStatuses'
import { RootState } from '@/store'
import { isArchiveJob } from './helper'
// import { checkCurrencyBackendId } from '@/utils/currency'
import {
  STAGE_BLOCKED_BY_FREELANCER, STAGE_COMPLETED,
  STAGE_DEADLINE_OVERDUE,
  STAGE_DISPUTED,
  STAGE_IN_PROGRESS,
  STAGE_NEW,
  STAGE_STARTED
} from '@/constants/job/jobStages'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'
import JobApplication from '@/models-ts/job/JobApplication'

export default {
  async getProfileInfo ({ commit, state }) {
    if (!state.profileInfo.isLoading) {
      try {
        commit('setProfileInfoLoading')
        const data = await getProfileInfo()
        commit('setProfileInfoLoaded', DashboardProfileInfo.fromServer(data))
      } catch (e) {
        console.error(e)
        commit('rejectProfileInfoLoading')
        throw e
      }
    }
  },
  async getCustomerJobs ({ commit }) {
    commit('setCustomerJobsLoading')
    const { jobs } = await getMyCustomerJobList({ limit: 1000, offset: 0 })
    const draft = []
    const active = []
    const archive = []
    for (let job of jobs.map(MyCustomerJobListItem.fromServer)) {
      if (isArchiveJob(job)) {
        archive.push(job)
      } else if (job.status === STATUS_DRAFT) {
        draft.push(job)
      } else {
        active.push(job)
      }
    }
    commit('setCustomerJobsLoaded', { draft, active, archive })
  },
  async getCustomerGigs ({ commit }) {
    commit('setCustomerGigsLoading')
    const [{ applications }, { offers }, { offers: completed }] = await Promise.all([
      getCustomerNegotiationsOffers(1000, 0),
      getCustomerInProgressOffers(1000, 0),
      getCustomerCompleted(1000, 0)
    ])
    const applicationModels = applications.map(MyGigApplication.fromServer)
    const active = [
      ...applicationModels
        .filter((app: MyGigApplication) => [ModerationStages.MANUAL, ModerationStages.PASSED].includes(app.moderationStage)),
      ...offers.map(GigOfferListItem.fromServer),
    ]
    const archive = [
      ...completed.map(GigOfferListItem.fromServer),
      ...applicationModels.filter((app: MyGigApplication) => app.moderationStage === ModerationStages.FAILED),
    ]
    commit('setCustomerGigsLoaded', { active, archive })
  },
  async getCustomerVacancies ({ commit }) {
    commit('setCustomerVacanciesLoading')
    const [{ vacancies: activeV }, { vacancies: draftsV }, { vacancies: completedV }] = await Promise.all([
      getMyPostedVacancies(1000, 0),
      getMyDraftsVacancies(1000, 0),
      getMyCompletedVacancies(1000, 0)
    ])
    const active = activeV.map(MyVacancyListItem.fromServer)
    const draft = draftsV.map(MyVacancyListItem.fromServer)
    const archive = completedV.map(MyVacancyListItem.fromServer)
    commit('setCustomerVacanciesLoaded', { active, archive, draft })
  },
  async getFreelancerJobs ({ commit }) {
    commit('setFreelancerJobsLoading')
    const { applications: activeApplications } = await getWorkerList({
      limit: 100,
      offset: 0,
      jobStages: [STAGE_NEW, STAGE_STARTED, STAGE_IN_PROGRESS, STAGE_BLOCKED_BY_FREELANCER],
      applicationStatuses: [JobApplicationStatuses.NEW],
    })
    const activeJobs = activeApplications.map(JobApplication.fromServer)
    const { applications: archiveApplications } = await getWorkerList({
      limit: 100,
      offset: 0,
      jobStages: [
        STAGE_NEW,
        STAGE_STARTED,
        STAGE_IN_PROGRESS,
        STAGE_DEADLINE_OVERDUE,
        STAGE_BLOCKED_BY_FREELANCER,
        STAGE_DISPUTED,
        STAGE_COMPLETED
      ],
      applicationStatuses: [JobApplicationStatuses.ARCHIVED],
    })
    const archiveJobs = archiveApplications.map(JobApplication.fromServer)
    commit('setFreelancerJobsLoaded', { active: activeJobs, archive: archiveJobs })
  },
  async getFreelancerGigs ({ commit }) {
    commit('setFreelancerGigsLoading')
    const [
      { gigs: publishedGigs },
      { gigs: draftGigs },
      { gigs: failedGigs },
      { offers: completed },
      { applications },
      { offers }
    ] = await Promise.all([
      getMyServices({
        limit: 1000,
        offset: 0,
        status: GigStatuses.PUBLISHED,
        moderationStages: [ModerationStages.PREMODERATION, ModerationStages.MANUAL, ModerationStages.PASSED]
      }),
      getMyServices({
        limit: 1000,
        offset: 0,
        status: GigStatuses.DRAFT,
        moderationStages: [ModerationStages.PREMODERATION, ModerationStages.MANUAL, ModerationStages.PASSED],
      }),
      getMyServices({
        limit: 1000,
        offset: 0,
        moderationStages: [ModerationStages.FAILED],
      }),
      getFreelancerCompleted(1000, 0),
      getFreelancerNegotiationsOffers(1000, 0),
      getFreelancerInProgress(1000, 0),
    ])
    const archive = [
      ...completed
        // .filter((backendModel: any) => {
        //   const gigJob = backendModel?.relations?.GigJob
        //   const blockchain = gigJob?.blockchain
        //   const currencyId = gigJob?.currency
        //   return checkCurrencyBackendId(blockchain, currencyId)
        // }) // Don't remove, filter for passed incorrect currency and blockchain
        .map(GigOfferListItem.fromServer),
      ...failedGigs
        // .filter((backendModel: any) => {
        //   const gigJob = backendModel?.relations?.GigJob
        //   const blockchain = gigJob?.blockchain
        //   const currencyId = gigJob?.currency
        //   return checkCurrencyBackendId(blockchain, currencyId)
        // }) // Don't remove, filter for passed incorrect currency and blockchain
        .map(MyGigListItem.fromServer),
    ]
    const draft = draftGigs.map(MyGigListItem.fromServer)
    const active = [
      ...applications
        // .filter((backendModel: any) => {
        //   const gigJob = backendModel?.relations?.GigJob
        //   const blockchain = gigJob?.blockchain
        //   const currencyId = gigJob?.currency
        //   return checkCurrencyBackendId(blockchain, currencyId)
        // }) // Don't remove, filter for passed incorrect currency and blockchain
        .map(MyGigApplication.fromServer),
      ...offers
        // .filter((backendModel: any) => {
        //   const gigJob = backendModel?.relations?.GigJob
        //   const blockchain = gigJob?.blockchain
        //   const currencyId = gigJob?.currency
        //   return checkCurrencyBackendId(blockchain, currencyId)
        // }) // Don't remove, filter for passed incorrect currency and blockchain
        .map(GigOfferListItem.fromServer),
      ...publishedGigs.map(MyGigListItem.fromServer),
    ]
    commit('setFreelancerGigsLoaded', { active, draft, archive })
  },
  async getFreelancerVacancies ({ commit }) {
    commit('setFreelancerVacanciesLoading')
    const { applications } = await getMyFreelancerApplications({ limit: 1000, offset: 0 })
    const active = []
    const archive = []
    const vacancies = applications.map(VacancyApplication.fromServer)
    for (let vac of vacancies) {
      if ([VacancyApplicationStatuses.NEW, VacancyApplicationStatuses.IN_PROGRESS].includes(vac.status)) {
        active.push(vac)
      } else {
        archive.push(vac)
      }
    }
    commit('setFreelancerVacanciesLoaded', { active, archive })
  },
} as ActionTree<IDashboardState, RootState>
