
export const INPUT_FORMATS = {
  ETH: 0,
  WEI: 1,
  GWEI: 2,
  USDT: 3,
  USDT_BASE_UNITS: 4,
}

export const OUTPUT_FORMATS = {
  CURRENCY: 0,
  CURRENCY_COMISSION: 1,
}
