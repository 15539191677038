export default class VacancyLocation {
  city: string
  citySlug: string
  country: string
  countrySlug: string

  constructor (props: Partial<VacancyLocation>) {
    Object.assign(this, props)
  }

  static fromServer ({ city, citySlug, country, countrySlug }: VacancyLocationFromServer) {
    return new VacancyLocation({
      city,
      citySlug,
      country,
      countrySlug,
    })
  }

  get location () {
    if (!this.city && !this.country) {
      return null
    }
    if (this.city && this.city !== this.country) {
      return `${this.city}, ${this.country}`
    }
    return this.city
  }
}

export interface VacancyLocationFromServer {
  city: string
  citySlug: string
  country: string
  countrySlug: string
}
