import { render, staticRenderFns } from "./SkillsMenu.htm?vue&type=template&id=d9bb51e8&scoped=true&"
import script from "./SkillsMenu.ts?vue&type=script&lang=js&"
export * from "./SkillsMenu.ts?vue&type=script&lang=js&"
import style0 from "./SkillsMenu.scss?vue&type=style&index=0&id=d9bb51e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9bb51e8",
  null
  
)

export default component.exports