export enum MessageTypes {
  TEXT = 1,
  FILE = 2,
  IMAGE = 3,
  LINK = 4,
  JOB_OFFER = 5,
  JOB_MARK_IS_DONE = 6,
  JOB_IS_DONE = 7,
  JOB_DISPUTE = 8,
  JOB_REVIEW = 9,
  GIG_OFFER = 10,
  GIG_MARK_IS_DONE = 11,
  GIG_IS_DONE = 12,
  GIG_DISPUTE = 13,
  GIG_REVIEW = 14,
  USER_BAN = 15,
  ROOM_CLOSED = 16,
  GIG_REFUNDED = 17,
  JOB_REFUNDED = 18,
  GIG_DISPUTE_RESOLVED = 19,
  JOB_DISPUTE_RESOLVED = 20,
  USER_UNBAN = 21,
  JOB_REMOVED = 22,
  JOB_UNPUBLISHED = 23,
  JOB_ROOM_UNLOCKED = 24,
  VACANCY_REMOVED = 25,
  VACANCY_UNPUBLISHED = 26,
  // VACANCY_ROOM_UNLOCKED = 27,
  VACANCY_APPLICATION_APPLIED = 28,
  VACANCY_APPLICATION_DECLINED_BY_CUSTOMER = 29,
  VACANCY_APPLICATION_DECLINED_BY_FREELANCER = 30,
  GIG_UNPUBLISHED = 31,
  VACANCY_ARCHIVED = 32,
  GIG_REMOVED = 33,
}
