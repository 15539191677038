export enum WorkExperience {
  YEAR = 1,
  THREE_YEARS = 2,
  MORE_THREE_YEARS = 3,
  NOT_SET = 4,
}

export const ExperienceExplanation = {
  [WorkExperience.YEAR]: 'Less than 1 year',
  [WorkExperience.THREE_YEARS]: 'From 1 to 3 years',
  [WorkExperience.MORE_THREE_YEARS]: 'More than 3 years',
  [WorkExperience.NOT_SET]: null,
}
