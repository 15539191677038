import maxBy from 'lodash/maxBy'
import { FileCategories } from '@/constants/backend/FileCategories'
import { getUrl } from '@/utils/file'
import { parseJson } from '@/utils/parser'
import { Avatar } from '@/models/user'

const MAX_IMAGE_WIDTH = 1600

export default class Image {
  id: number | null
  description: string | null
  name: string | null
  private _backendData: any
  private _src: string | null
  private _srcSet: string | null
  private _base64: string | null

  constructor (data?: Partial<Image> & { backendData: any; src: string; srcSet: string; base64?: string | null }) {
    Object.assign(this, {
      id: data?.id,
      name: data?.name,
      description: data?.description,
      _src: data?.src,
      _srcSet: data?.srcSet,
      _backendData: data?.backendData,
      _base64: data?.base64 || ''
    })
  }

  static fromServer (data: ImageFromServer[] | ImageFromServer) {
    const imagesData = (Array.isArray(data) ? data : [data]).filter(Boolean)
    if (imagesData?.length) {
      interface ImageData {
        src: string
        width: string
      }
      const images = imagesData
        .map(file => {
          const width = parseJson(file.meta).width
          if (width && width < MAX_IMAGE_WIDTH) {
            return { src: getUrl(file), width }
          }
        })
        .filter(Boolean) as ImageData[]
      const maxImage = maxBy(images, 'width')
      const srcSet = images
        .map(file => `${file.src} ${file.width}w`)
        .join(', ')
      const original = imagesData.find(file => !file.category || file.category === FileCategories.CAT_IMG_ORIGINAL)
      return new Image({
        id: original?.id || null,
        src: maxImage?.src || getUrl(original),
        srcSet: srcSet,
        name: original?.name || null,
        description: original?.description || null,
        backendData: imagesData,
      })
    }
    return new Image()
  }

  static arrayFromServer (images: ImageFromServer[]) {
    return (images || [])
      .filter(file => !file.category || file.category === FileCategories.CAT_IMG_ORIGINAL)
      .map(orig => [orig, ...images.filter(file => file.filename.includes(orig.filename))])
      .map(img => Image.fromServer(img))
  }

  setBase64 (file: string | null) {
    this._base64 = file
  }

  clear () {
    this._base64 = null
    this._src = null
    this._srcSet = null
    this._backendData = null
  }

  toServer () {
    return this._base64 ? [{ base64: this._base64 }] : this._backendData
  }

  toAvatar () {
    return new Avatar({
      id: this.id,
      src: this.src,
      srcSet: this.srcSet,
      description: this.description,
      name: this.name,
    })
  }

  get src (): string | null {
    return this._base64 || this._src
  }

  get srcSet (): string | null {
    return this._base64 || this._srcSet
  }
}

export interface ImageFromServer {
  id: number
  category: FileCategories
  meta: string
  src: string
  created_at: string
  name: string
  filename: string
  ext: string
  source: string
  path: string
  description: string
}
