import { render, staticRenderFns } from "./Avatar.htm?vue&type=template&id=67cad5d6&scoped=true&"
import script from "./Avatar.js?vue&type=script&lang=js&"
export * from "./Avatar.js?vue&type=script&lang=js&"
import style0 from "./Avatar.scss?vue&type=style&index=0&id=67cad5d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67cad5d6",
  null
  
)

export default component.exports