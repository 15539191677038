import { Blockchain } from '@/constants/blockchain'

export interface SendTransactionEvents {
  'transactionHash': (hash: string) => void;
  'error': (err: Error) => void;
}

type ProvidersByChainIdType = {
  [key: string]: string
}

type ProvidersByBlockchainType = {
  [key: number]: string
}

export const ProviderByBlockchain: ProvidersByBlockchainType = {
  [Blockchain.Ethereum]: process.env.VUE_APP_WEB3_PROVIDER_ETHEREUM || '',
  [Blockchain.Binance]: process.env.VUE_APP_WEB3_PROVIDER_BINANCE || '',
  [Blockchain.Polygon]: process.env.VUE_APP_WEB3_PROVIDER_POLYGON || '',
  [Blockchain.Fantom]: process.env.VUE_APP_WEB3_PROVIDER_FANTOM || '',
  [Blockchain.Arbitrum]: process.env.VUE_APP_WEB3_PROVIDER_ARBITRUM || '',
  [Blockchain.Optimism]: process.env.VUE_APP_WEB3_PROVIDER_OPTIMISM || '',
  [Blockchain.Base]: process.env.VUE_APP_WEB3_PROVIDER_BASE || '',
}

const EthereumChainIdUrl: string = process.env.VUE_APP_NETWORK_ETHEREUM || ''
const BinanceChainIdUrl: string = process.env.VUE_APP_NETWORK_BINANCE || ''
const PolygonChainIdUrl: string = process.env.VUE_APP_NETWORK_POLYGON || ''
const FantomChainIdUrl: string = process.env.VUE_APP_NETWORK_FANTOM || ''
const ArbitrumChainIdUrl: string = process.env.VUE_APP_NETWORK_ARBITRUM || ''
const OptimismChainIdUrl: string = process.env.VUE_APP_NETWORK_OPTIMISM || ''
const BaseChainIdUrl: string = process.env.VUE_APP_NETWORK_BASE || ''

export const ProvidersByChainId: ProvidersByChainIdType = {
  [EthereumChainIdUrl]: process.env.VUE_APP_WEB3_PROVIDER_ETHEREUM || '',
  [BinanceChainIdUrl]: process.env.VUE_APP_WEB3_PROVIDER_BINANCE || '',
  [PolygonChainIdUrl]: process.env.VUE_APP_WEB3_PROVIDER_POLYGON || '',
  [FantomChainIdUrl]: process.env.VUE_APP_WEB3_PROVIDER_FANTOM || '',
  [ArbitrumChainIdUrl]: process.env.VUE_APP_WEB3_PROVIDER_ARBITRUM || '',
  [OptimismChainIdUrl]: process.env.VUE_APP_WEB3_PROVIDER_OPTIMISM || '',
  [BaseChainIdUrl]: process.env.VUE_APP_WEB3_PROVIDER_BASE || '',
}
