import Joi from 'joi'
import { Blockchain, ChainId } from '@/constants/blockchain'
import AbstractModel from './AbstractModel'
import {
  CURRENCIES
} from '@/constants/currency'

const getCurrencyMap = (currencies) => (currencies || []).reduce((acc, cur) => {
  acc[cur.name] = '0'
  return acc
}, {})

class Balances extends AbstractModel {
  static propTypes = {
    ...Object.keys(ChainId).reduce((accum, blockchain) => ({
      ...accum,
      [blockchain]: Joi.object().default(getCurrencyMap(
        CURRENCIES.filter((currency) => currency.blockchain === parseInt(blockchain))
      ))
    }), {})
  }
}

export default Balances
