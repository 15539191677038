import { Module } from 'vuex'
import CustomerDetails, { CustomerDetailsFromServerProps } from '@/models-ts/user/details/CustomerDetails'
import LoadableModel from '@/models-ts/LoadableModel'
import { getCustomer } from '@/api/usersAndProfiles/profiles'
import { ICustomerDetailsState } from './types'
import { getPublicJobsList } from '@/api/jobs/lists'
import JobListItem, { JobListItemServerProps } from '@/models-ts/job/JobListItem'
import VacancyListItem, { VacancyListItemFromServer } from '@/models-ts/vacancies/VacancyListItem'
import { getVacancies } from '@/api/vacancies'

const getInitialState = (): ICustomerDetailsState => ({
  details: new LoadableModel(),
  jobs: new LoadableModel({ value: [] }),
  vacancies: new LoadableModel({ value: [] }),
  prefetched: false,
})

export default (): Module<ICustomerDetailsState, any> => {
  return {
    namespaced: true,
    state: getInitialState(),
    getters: {
      customer: (state) => state.details.value && CustomerDetails.fromServer(state.details.value),
      isLoading: (state) => state.details.isLoading,
      jobs: (state) => state.jobs.value ? state.jobs.value.map(JobListItem.fromServer) : [],
      vacancies: (state) => (state.vacancies.value && state.vacancies.value.map(VacancyListItem.fromServer)) || [],
    },
    mutations: {
      resetState (state) {
        Object.assign(state, getInitialState())
      },
      clearCustomer (state) {
        state.details = new LoadableModel()
        state.jobs = new LoadableModel({ value: [] })
        state.vacancies = new LoadableModel({ value: [] })
      },
      beforeReady (state) {
        state.details = new LoadableModel(state.details)
        state.jobs = new LoadableModel(state.jobs)
        state.vacancies = new LoadableModel(state.vacancies)
      },
      setPrefetched (state, flag: boolean) {
        state.prefetched = flag
      },
      setCustomerLoading (state) {
        state.details.loading()
      },
      setCustomerLoaded (state, customer: CustomerDetailsFromServerProps) {
        state.details.loaded(customer)
      },
      setJobsLoading (state) {
        state.jobs.loading()
      },
      setJobsLoaded (state, jobs: Array<JobListItemServerProps>) {
        state.jobs.loaded(jobs)
      },
      setVacanciesLoading (state) {
        state.vacancies.loading()
      },
      setVacanciesLoaded (state, vacancies: Array<VacancyListItemFromServer>) {
        state.vacancies.loaded(vacancies)
      },
    },
    actions: {
      async load ({ commit }, id: number) {
        commit('setCustomerLoading')
        const customer = await getCustomer(id)
        commit('setCustomerLoaded', customer)
        commit('setJobsLoading')
        const { jobs } = await getPublicJobsList({
          limit: 100,
          offset: 0,
          customerId: id,
        })
        commit('setJobsLoaded', jobs)
        commit('setVacanciesLoading')
        const { vacancies } = await getVacancies({
          limit: 100,
          offset: 0,
          customerId: id,
        })
        commit('setVacanciesLoaded', vacancies)
      }
    },
  }
}
