import { JobStage } from '@/constants/job/jobStages'
import { BACKEND_PRIVATE, BACKEND_PUBLIC } from '../base'
import { JobStatus } from '@/constants/job/jobStatuses'

export interface BrowseJobsFilter {
  limit?: number
  offset?: number
  skills?: Array<string>
  search?: string
  orderField?: 'date' | 'rate',
  orderType?: 'asc' | 'desc',
  rateFrom?: string | number,
  rateTo?: string | number,
  avgReviews?: number,
  customerId?: number
}

export async function getMyCustomerJobList (
  { stages, limit, offset }:
    { stages?: Array<JobStage>, limit: number, offset: number }
) {
  return BACKEND_PRIVATE.get('/me/simple-jobs/list-as-customer', {
    params: {
      stages,
      limit,
      offset,
    }
  })
}

export async function getMyCustomerCompletedJobList ({ limit, offset }: { limit: number, offset: number }) {
  return BACKEND_PRIVATE.get('/me/simple-jobs/list-as-customer-of-completed', {
    params: {
      limit,
      offset,
    }
  })
}

// TODO New Request
export async function getWorkerList (
  { limit, offset, jobStages = [], applicationStatuses = [] }:
    { limit: number, offset: number, jobStages?: JobStage[], applicationStatuses?: JobStatus[] }
) {
  return BACKEND_PRIVATE.get('/me/jobs/simple/applications/list-as-freelancer', {
    params: {
      limit: limit,
      offset,
      jobStages,
      applicationStatuses,
    }
  })
}

export async function getPublicJobsList (params: BrowseJobsFilter) {
  return BACKEND_PUBLIC.get('/simple-jobs/list', { params })
}

export async function getCountCustomerContracts () {
  return BACKEND_PRIVATE.get('me/jobs/employer/count')
}

export async function getCountFreelancerContracts () {
  return BACKEND_PRIVATE.get('me/jobs/worker/count')
}

export async function getRecommendedJobs ({ limit, offset }: { limit: number, offset: number }) {
  return BACKEND_PRIVATE.get('/me/simple-jobs/list-of-recommended', {
    params: {
      limit: limit,
      offset: offset
    }
  })
}
