var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"select",staticClass:"lx-select",class:{ fixed: _vm.fixed, 'with-error': _vm.errorMsg, 'static-err': _vm.errStatic, 'with-native-select': _vm.native }},[(_vm.loading)?_c('lx-skeleton-loader',{staticClass:"select-loader",attrs:{"fill":""}}):_vm._e(),_c('div',{staticClass:"select-container"},[_c('multiselect',_vm._g(_vm._b({staticClass:"multiselect",class:{
      'multiselect-default': _vm.type === _vm.SELECT_DEFAULT,
      'multiselect-rounded': _vm.type === _vm.SELECT_ROUNDED || _vm.rounded,
      'multiselect-white': _vm.type === _vm.SELECT_WHITE,
      'multiselect-group': _vm.optionsGroup,
      'multiselect-readonly': _vm.readonly,
      },attrs:{"searchable":_vm.isSearchable,"placeholder":_vm.placeholderSelect,"selectLabel":"","deselectLabel":"","selectedLabel":"","disabled":_vm.disabled || _vm.readonly,"label":_vm.label,"options":_vm.sortedOptions,"value":_vm.value},scopedSlots:_vm._u([{key:"noResult",fn:function(){return [_vm._t("noResults",function(){return [_c('span',[_vm._v("No results")])]})]},proxy:true},{key:"caret",fn:function(toggle){return [_vm._t("caret",function(){return [_c('div',{staticClass:"multiselect-select"},[_c('lx-icon',{staticClass:"toggle-icon",attrs:{"icon":"arrow-down","size":"10,7"}})],1)]},null,toggle)]}},{key:"singleLabel",fn:function(props){return [_vm._t("singleLabel",null,null,props)]}},(_vm.defaultOption)?{key:"option",fn:function(props){return [_vm._t("option",null,null,props)]}}:(_vm.optionsGroup)?{key:"option",fn:function(ref){
      var option = ref.option;
return [_c('div',{staticClass:"option-container",class:{
          'group-option': option.isParent,
          'group-expanded': [_vm.rootExpanded, _vm.childExpanded].includes(option.id),
          'child-option': option.isChild,
          'grandchild-option': option.isGrandchild,
        },on:{"click":function($event){return _vm.onClickOption(option, $event)}}},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(option.name))]),(option.isParent)?_c('lx-button',{staticClass:"group-btn",attrs:{"theme":"icon","icon":"arrow-down","iconSize":"10,7"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickExpand(option, option.isChild, $event)}}}):_vm._e()],1)]}}:null],null,true)},'multiselect',_vm.$attrs,false),Object.assign({}, _vm.$listeners,
        {input: _vm.onInput,
        open: _vm.openSelect,
        'search-change': _vm.onSearchChange})),[_c('template',{slot:"placeholder"},[_vm._t("placeholder")],2),_c('template',{slot:"tag"},[_vm._t("tag")],2),_c('template',{slot:"noOptions"},[_vm._t("noOptions")],2)],2),(_vm.showNative)?_c('no-ssr',[_c('select',{staticClass:"native-select",attrs:{"disabled":_vm.disabled || _vm.readonly},on:{"input":_vm.onNativeInput}},_vm._l((_vm.options),function(option,id){return _c('option',{key:id,domProps:{"selected":_vm.optionIsSelected(option)}},[_vm._v(_vm._s(option[_vm.label]))])}),0)]):_vm._e()],1),_c('transition',{attrs:{"name":"slide"}},[(_vm.errorMsg)?_c('div',{staticClass:"errors-container"},[_c('div',{staticClass:"error-msg",domProps:{"innerHTML":_vm._s(_vm.errorMsg)}})]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }