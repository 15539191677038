import LxButtonNew from '@/components/ButtonNew/Button'

export default {
  components: { LxButtonNew },
  inheritAttrs: false,
  name: 'lx-input-with-button',
  props: {
    buttonText: String,
    errorMsg: String,
    loading: Boolean,
    disabled: Boolean
  },
  computed: {
    inputAttrs () {
      const { loading, ...attrs } = this.$attrs
      return attrs
    },
    inputListeners () {
      const { click, ...listeners } = this.$listeners
      return listeners
    },
    disabledInner () {
      return this.loading || this.disabled
    }
  },
  methods: {
    onButtonClick (event) {
      this.$emit('click', event)
    }
  }
}
